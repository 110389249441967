import DescriptionIcon from "@mui/icons-material/Description"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Divider, Link, Stack, Typography } from "@mui/material"
import { styled } from "@mui/system"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { hasId } from "../utils"
import LessonsList from "./lessons_list"

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "none",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
  "& .MuiAccordionSummary-root": {
    cursor: "pointer",
    fontSize: "1rem",
    height: 50,
    minHeight: 50,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    backgroundColor: theme.palette.action.selected,
    minHeight: 50,
  },
  "& .MuiAccordionDetails-root": {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
}))

const LevelTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.text.primary,
  letterSpacing: "0",
}))

// Main Component
const CollapsibleTable = ({ programLevels, preAssessments, assignmentId, curriculumAssessmentsEnabled }) => {
  const expandedAccordionRef = useRef(null)

  useEffect(() => {
    if (expandedAccordionRef.current) {
      expandedAccordionRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [])

  const PreAssessmentRows = () => {
    const isExpanded = preAssessments.some(assessment => assessment.id === assignmentId)
    return (
      <StyledAccordion defaultExpanded={isExpanded} ref={isExpanded ? expandedAccordionRef : null}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <LevelTitle>Diagnostic Assessment</LevelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Stack divider={<Divider />} spacing={2}>
            {preAssessments.map(row => (
              <PreAssessmentRow key={row.id} row={row} />
            ))}
          </Stack>
        </AccordionDetails>
      </StyledAccordion>
    )
  }

  const ProgramLevelRows = programLevels.map(row => {
    const isExpanded = hasId(row, assignmentId)
    const ref = isExpanded ? expandedAccordionRef : null
    return (
      <StyledAccordion key={row.id} defaultExpanded={isExpanded} ref={ref}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <LevelTitle>{row.name}</LevelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <LessonsList lessons={row.lessons} level={row.level_number} />
        </AccordionDetails>
      </StyledAccordion>
    )
  })

  return (
    <Stack>
      {curriculumAssessmentsEnabled && <PreAssessmentRows />}
      {ProgramLevelRows}
    </Stack>
  )
}

// PreAssessmentRow Component
const PreAssessmentRow = ({ row }) => (
  <Stack spacing={1} padding={2.5}>
    <Typography variant="subtitle1" fontWeight="bold">
      {row.name}
    </Typography>
    {row.description && (
      <Typography component="p" variant="body2" color="text.primary">
        {row.description}
      </Typography>
    )}
    {row.url && (
      <Stack direction="row" spacing={1}>
        <Link
          href={row.url}
          variant="body2"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ wordWrap: "break-word" }}
          aria-label={`Pre-assessment link for ${row.name}`}
          underline="hover"
          color="primary"
        >
          <DescriptionIcon sx={{ color: "primary.main", mr: 0.5 }} />
          {row.type || row.name}
        </Link>
      </Stack>
    )}
  </Stack>
)

PreAssessmentRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

CollapsibleTable.propTypes = {
  preAssessments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  ),
  programLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      lessons: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
          description: PropTypes.string,
          lesson_resources: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string,
              resource_type: PropTypes.string.isRequired,
              resource_url: PropTypes.string.isRequired,
              show_icon: PropTypes.bool,
            })
          ),
          url: PropTypes.string,
        })
      ),
      level_number: PropTypes.number,
    })
  ).isRequired,
  assignmentId: PropTypes.string,
}

CollapsibleTable.defaultProps = {
  preAssessments: [],
  assignmentId: null,
}

export default CollapsibleTable
