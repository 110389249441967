/* eslint-disable no-console */
const isDevelopment = process.env.NODE_ENV === "development"

const noop = () => {}

const createLogger = () => ({
  info: isDevelopment ? console.log.bind(console) : noop,
  warn: isDevelopment ? console.warn.bind(console) : noop,
  error: isDevelopment ? console.error.bind(console) : noop,
})

export default createLogger()
