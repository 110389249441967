import React, { useRef, useState } from "react"
import { Button, Card, Container, Row } from "react-bootstrap"
import { Form } from "react-final-form"

import { apiClient, showBaseErrorsInAlert } from "@/api"
import { getFullName } from "@/helpers/presentational"
import { clearFieldData, clearFieldsData, getSubmitErrors, setFieldData } from "../../../helpers/forms"
import UsersNavbar from "../../blocks/UsersNavbar"
import PaymentCard from "../../schedule_session/PaymentCard"

const PaymentInfo = ({ dataSources: { user, urls, paymentData } }) => {
  const handleSubmit = async values => {
    if (cardFormRef.current && !values.cc_token) {
      if (cardFormRef.current.requestSubmit) {
        cardFormRef.current.requestSubmit()
      } else if (cardSubmitButtonRef.current) {
        cardSubmitButtonRef.current.click()
      }
    } else {
      try {
        if (values.cc_token) {
          await apiClient.put(urls.complete_signup)
          await apiClient.put(urls.save, { cc_token: values.cc_token })
        }
        window.location.replace(urls.next)
      } catch (e) {
        showBaseErrorsInAlert(e)
        return getSubmitErrors(e)
      }
    }
  }

  const cardFormRef = useRef()
  const cardSubmitButtonRef = useRef()
  const [isPendingCardTokenRequest, setIsPendingCardTokenRequest] = useState(false)

  return (
    <Form mutators={{ setFieldData, clearFieldData, clearFieldsData }} onSubmit={handleSubmit}>
      {({ handleSubmit, submitting }) => (
        <>
          <UsersNavbar title="Add payment information" />
          <Container className="panel-wrapper">
            <Card className="panel mb-4" body>
              <h4>Payment details</h4>
              <PaymentCard
                {...paymentData}
                initialCardholderName={getFullName(user).toUpperCase()}
                cardFormRef={cardFormRef}
                cardSubmitButtonRef={cardSubmitButtonRef}
                onRequestStart={() => setIsPendingCardTokenRequest(true)}
                onRequestEnd={() => setIsPendingCardTokenRequest(false)}
                showDefaultText={false}
              />
            </Card>
          </Container>
          <Container className="pb-5 bg-light-gray">
            <Row className="flex-nowrap mb-5 flex-column-reverse flex-md-row justify-content-center justify-content-md-end">
              <Button
                variant="outline-primary"
                className="mb-4 mb-md-0"
                disabled={submitting || isPendingCardTokenRequest}
                onClick={handleSubmit}
              >
                Save and continue
              </Button>
            </Row>
          </Container>
        </>
      )}
    </Form>
  )
}

export default PaymentInfo
