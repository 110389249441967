import { find, map, reject } from "lodash/fp"
import { stringify } from "query-string"
import React, { useEffect, useMemo, useState } from "react"

import { apiClient, replaceId, showAllErrorsInAlert } from "@/api"
import Alerter from "@/common/alerter"
import { PENDING_REQUEST } from "@/common/constants"
import { getLabelByValue } from "@/helpers/presentational"
import { serializeRequestOptions } from "@/helpers/serializers"
import IndeterminateProgressBar from "../../blocks/IndeterminateProgressBar"
import SingleChoiceDropdown from "../../blocks/SingleChoiceDropdown"
import RequestModal from "../../schedule_session/RequestModal"
import useFocusedSession from "../useFocusedSession"
import SessionRequestCard from "./SessionRequestCard"

const statusOptions = [
  { value: PENDING_REQUEST, label: "Active requests" },
  { value: null, label: "All requests" },
]

const getStatusFilterText = status => `Show: ${getLabelByValue(statusOptions)(status).toLowerCase()}`

const SessionRequests = ({ dataSources, paymentData, urls }) => {
  const [status, setStatus] = useState(PENDING_REQUEST)
  const [requests, setRequests] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [editRequestId, setEditRequestId] = useState(null)

  const focusedSessionId = useFocusedSession()

  useEffect(() => {
    if (focusedSessionId) {
      setStatus(null)
      getReviews(null)
    } else {
      getReviews(status)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getReviews = async status => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(urls.get, { params: { filter: status } })
      setRequests(data)
    } catch (e) {
      Alerter.error("An error occurred while loading data")
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeStatus = value => {
    getReviews(value)
    setStatus(value)
  }

  const showEditRequestModal = id => {
    window.history.replaceState(null, null, `?${stringify({ id })}`)
    setEditRequestId(id)
  }

  const closeEditRequestModal = () => {
    window.history.replaceState(null, null, window.location.pathname)
    setEditRequestId(null)
  }

  const options = useMemo(() => serializeRequestOptions(dataSources), [dataSources])

  const currentRequest = useMemo(() => {
    if (editRequestId) {
      return find(["id", editRequestId])(requests)
    }
    return null
  }, [editRequestId, requests])

  const editRequest = async values => {
    try {
      const editUrl = replaceId(urls.edit, values.id)
      const { data } = await apiClient.put(editUrl, values)
      Alerter.success("Request was updated")

      const updatedReqests = map(request => (request.id === values.id ? data : request))(requests)
      setRequests(updatedReqests)
      closeEditRequestModal()
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const deleteRequest = async id => {
    try {
      const deleteUrl = replaceId(urls.delete, id)
      await apiClient.delete(deleteUrl)
      Alerter.success("Request was deleted")

      const filteredRequests = reject(["id", id])(requests)
      setRequests(filteredRequests)
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  return (
    <>
      {isLoading && <IndeterminateProgressBar />}
      <div className="p-4 p-md-5">
        <SingleChoiceDropdown
          value={status}
          className="mb-4"
          buttonText={getStatusFilterText(status)}
          options={statusOptions}
          onChange={onChangeStatus}
        />
        {!isLoading && !requests.length && <p className="text-center m-0">No requests found</p>}
        {requests.map(request => (
          <SessionRequestCard
            key={request.id}
            options={options}
            sessionRequest={request}
            focused={focusedSessionId === request.id}
            saveMessageUrl={urls.save_message}
            enableMessageButtonUrl={urls.enable_message_button}
            onRequestDelete={() => deleteRequest(request.id)}
            onRequestEdit={() => showEditRequestModal(request.id)}
          />
        ))}
      </div>
      {currentRequest && (
        <RequestModal
          show={true}
          handleClose={closeEditRequestModal}
          dataSources={{ ...dataSources, tutor: currentRequest.tutor, request: currentRequest }}
          paymentData={{
            ...paymentData,
            parentHasCard: !!currentRequest.cc_details,
            ccDetails: currentRequest.cc_details,
          }}
          urls={{ availability: replaceId(urls.availability, currentRequest.tutor.id) }}
          timeZone={dataSources.parent.time_zone}
          hourRate={currentRequest.hourly_rate}
          onSubmit={editRequest}
        />
      )}
    </>
  )
}

export default SessionRequests
