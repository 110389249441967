import React, { useEffect, useState } from "react"
import { Button, Container } from "react-bootstrap"

import Turbolinks from "turbolinks"

import { apiClient, post } from "@/api"
import { getQueryStringFromParams } from "../find_tutor/serializeParams"
import MessageConfirmationModal from "./MessageConfirmationModal"
import MessageFormModal from "./MessageFormModal"

const MessageButton = ({ buttonText, className, dataSources }) => {
  const [showMessageForm, setShowMessageForm] = useState(false)
  const [showMessageConfirmation, setShowMessageConfirmation] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [enableMessageButton, setEnableMessageButton] = useState(false)

  useEffect(() => {
    if (dataSources.recipient.id === null) return
    findEnableMessageButton(dataSources.urls.enable_message_button, {
      from_id: dataSources.from.id,
      recipient_id: dataSources.recipient.id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSources.from.id, dataSources.recipient.id])

  const findEnableMessageButton = async (url, params) => {
    const queryString = getQueryStringFromParams(params)
    try {
      //setIsLoading(true)
      const {
        data: { response },
      } = await apiClient.get(url + queryString)
      setEnableMessageButton(response)
    } catch (_) {
      setEnableMessageButton(false)
    } finally {
      //setIsLoading(false)
    }
  }

  const onCancelFormModal = () => {
    setShowMessageForm(false)
  }

  const onSubmitFormModal = values => {
    setIsSubmitting(true)
    post(dataSources.urls.save_message, values, onSubmitSuccess, onSubmitFinally)
  }

  const onSubmitSuccess = () => {
    setShowMessageForm(false)
    setShowMessageConfirmation(true)
  }

  const onSubmitFinally = () => {
    setIsSubmitting(false)
  }

  const onCloseConfirmationModal = () => {
    setShowMessageConfirmation(false)
    Turbolinks.visit(window.location)
  }

  return (
    <>
      {enableMessageButton && (
        <Container className="px-0">
          <Button
            type="button"
            variant="outline-primary"
            className={className}
            onClick={() => setShowMessageForm(true)}
          >
            {buttonText}
          </Button>
          <MessageFormModal
            show={showMessageForm}
            onCancel={onCancelFormModal}
            onSubmit={onSubmitFormModal}
            isSubmitting={isSubmitting}
            from={dataSources.from}
            recipient={dataSources.recipient}
            subject={dataSources.subject}
            changeSubject={dataSources.changeSubject}
          />
          <MessageConfirmationModal show={showMessageConfirmation} onClose={() => onCloseConfirmationModal()} />
        </Container>
      )}
    </>
  )
}

export default MessageButton
