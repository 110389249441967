import Alerter from "@/common/alerter"
import { ERROR_MESSAGES, HTTP_STATUS, SERVER_ERROR_MAP, generateSubmitError } from "@/constants"
import { joinErrors } from "../utils/apiUtils"

const getErrors = response => response?.data?.errors || response?.data?.error

const getErrorMessage = response => {
  if (!response) return ERROR_MESSAGES.NETWORK
  if (response.status === HTTP_STATUS.UNAUTHORIZED) return ERROR_MESSAGES.UNAUTHORIZED
  const serverError = getErrors(response)
  return typeof serverError === "string" ? serverError : SERVER_ERROR_MAP[response.status] || ERROR_MESSAGES.SERVER
}

export const showErrorMessage = error => {
  const message = getErrorMessage(error.response)
  if (message) Alerter.error(message)
}

/**
 * Handles API error responses by displaying appropriate alert messages.
 * @param {Object} response - The API response object containing error details.
 * @param {Function} customHandler - Custom handler function for processing unprocessable entity errors.
 * @returns {void}
 *
 * @example
 * handleAlertError(response, (error) => {
 *   // Custom error handling logic
 * });
 *
 * @throws {Error} Displays error alerts through Alerter service
 */
const handleAlertError = (response, customHandler) => {
  if (!response) {
    Alerter.error(ERROR_MESSAGES.NETWORK)
    return
  }

  const serverError = getErrors(response)

  if (response.status === HTTP_STATUS.UNAUTHORIZED) {
    Alerter.error(ERROR_MESSAGES.UNAUTHORIZED)
    return
  }

  if (typeof serverError === "string") {
    Alerter.error(serverError)
    return
  }

  if (response.status === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
    customHandler(response)
    return
  }

  Alerter.error(SERVER_ERROR_MAP[response.status] || ERROR_MESSAGES.SERVER)
}

const formatErrorMessage = (key, value) =>
  key !== "base" ? generateSubmitError(`${key} - ${joinErrors(value)}`) : value

const handleServerErrors = (serverErrors, errorHandler) => {
  if (!serverErrors) {
    Alerter.error(ERROR_MESSAGES.CANT_SUBMIT)
    return
  }

  if (typeof serverErrors === "string") {
    Alerter.error(serverErrors)
    return
  }

  errorHandler(serverErrors)
}
export const showAllErrorsInAlert = ({ response }) => {
  if (response && response.status !== HTTP_STATUS.UNAUTHORIZED) {
    handleAlertError(response, res => {
      handleServerErrors(getErrors(res), errors => {
        if (typeof errors === "object") {
          Object.entries(errors).forEach(([key, value]) => {
            Alerter.error(formatErrorMessage(key, value))
          })
        } else {
          Alerter.error(ERROR_MESSAGES.CANT_SUBMIT)
        }
      })
    })
  }
}

export const showBaseErrorsInAlert = ({ response }) => {
  if (response && response.status !== HTTP_STATUS.UNAUTHORIZED) {
    handleAlertError(response, res => {
      handleServerErrors(getErrors(res), errors => {
        if (errors.base) {
          Object.values(errors.base).forEach(Alerter.error)
        } else {
          Alerter.error(ERROR_MESSAGES.CANT_SUBMIT)
        }
      })
    })
  }
}
