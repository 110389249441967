import { DEFAULT_TIMEOUT } from "@/constants"
import { documentObj as document } from "../utils/apiUtils"

const CSRF_TOKEN = document.querySelector("[name=csrf-token]")?.content || ""

/**
 * Configuration object for API requests.
 * @type {Object}
 * @property {number} timeout - The timeout value for requests in milliseconds
 * @property {Object} headers - HTTP headers to be included in requests
 * @property {string} headers.Content-Type - Content type header, set to application/json
 * @property {string} headers.X-CSRF-TOKEN - CSRF token for request validation
 * @property {Function} validateStatus - Function to determine if response status is valid
 * @returns {boolean} True if status code is between 200-299, false otherwise
 */
export const apiConfig = {
  timeout: DEFAULT_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    "X-CSRF-TOKEN": CSRF_TOKEN,
  },
  validateStatus: status => status >= 200 && status < 300,
}
