import { formatDateString } from "@/helpers/dates"
import BRTThemeProvider from "@/providers/theme"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { nanoid } from "nanoid"
import React, { useCallback, useMemo } from "react"
import useFetchSessions from "../../hooks/useFetchSessions"
import DashboardCard from "../dashboardCard"

const SessionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.text.secondary,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  textTransform: "none",
  padding: theme.spacing(1, 2),
  justifyContent: "flex-start",
  width: "100%",
}))

const OfflineSessionButton = styled(SessionButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.text.secondary,
  cursor: "not-allowed",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}))

const TodaysSessionsDashboard = ({ date }) => {
  const { sessions, loading, error } = useFetchSessions(date)
  const today = useMemo(() => new Date(), [])
  const title = useMemo(
    () => (date.toDateString() === today.toDateString() ? "Today's Sessions" : `${formatDateString(date)}'s sessions`),
    [date, today]
  )

  const handleSessionClick = useCallback(url => {
    window.open(url, "_blank")
  }, [])

  if (loading) {
    return (
      <DashboardCard title={title}>
        <Box sx={{ width: "100%" }}>
          {[...Array(5)].map((_, index) => (
            <Typography variant="h5" key={index}>
              <Skeleton animation="wave" />
            </Typography>
          ))}
        </Box>
      </DashboardCard>
    )
  }

  if (error) {
    return (
      <DashboardCard title={title}>
        <Typography variant="text" color="error" className="text-center m-0">
          Error: {error}
        </Typography>
      </DashboardCard>
    )
  }

  return (
    <BRTThemeProvider>
      <DashboardCard title={title}>
        {!sessions.length ? (
          <Typography variant="text" className="text-center m-0">
            No Scheduled Sessions!
          </Typography>
        ) : (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            width="100%"
            sx={{ marginTop: 1 }}
          >
            {sessions.map(session => {
              const SessionComponent = session.online ? SessionButton : OfflineSessionButton
              return (
                <SessionComponent
                  onClick={session.online ? () => handleSessionClick(session.meeting_info) : null}
                  key={nanoid()}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{ fontSize: "1em", justifyContent: "space-between", width: "100%" }}
                  >
                    <Chip
                      label={session.time}
                      size="small"
                      sx={{ fontFamily: '"Poppins", sans-serif', fontWeight: 600, minWidth: "70px", fontSize: "1em" }}
                    />
                    <Box width="100%">
                      <Typography
                        sx={{
                          textAlign: "left",
                          lineHeight: "1.1em",
                          fontSize: "0.9rem",
                        }}
                      >
                        {session.students}
                      </Typography>
                    </Box>
                    {session.online && session.meeting_info && (
                      <Typography
                        variant="caption"
                        color="primary"
                        sx={{
                          lineHeight: "1em",
                          marginRight: "0.35rem",
                        }}
                      >
                        Join
                      </Typography>
                    )}
                  </Stack>
                </SessionComponent>
              )
            })}
          </Stack>
        )}
      </DashboardCard>
    </BRTThemeProvider>
  )
}

export default TodaysSessionsDashboard
