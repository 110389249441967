import { apiClient } from "@/api"
import GaugeComponent from "@/components/gauge"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"

const InstructionalDaysDetails = ({ startDate, endDate, valueOfGauge, daysRemaining }) => {
  const text = `${daysRemaining === 0 ? "No" : daysRemaining} ${daysRemaining > 0 ? "or more" : ""} days of instruction`

  return (
    <div className="d-flex h-100 w-100 justify-content-between align-items-start flex-column">
      <p className="text-left m-0" style={{ wordWrap: "break-word" }}>
        {startDate} to {endDate}
      </p>
      <GaugeComponent value={valueOfGauge} text={text} />
    </div>
  )
}

export const InstructionalDaysDashboard = () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const instructionalDaysResponse = async () => {
      try {
        const response = await apiClient.get("/api/v2/school_admins/instructional_days")
        setData(response && response.data)
      } catch (err) {
        setError(err)
      }
    }
    instructionalDaysResponse()
  }, [])

  if (error) {
    return <div>Error loading data</div>
  }

  if (!data) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: "100%",
          height: "240px",
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  const { dates, days_remaining: daysRemaining, days_completed: daysCompleted, total_days: totalDays } = data
  const valueOfGauge = totalDays === 0 ? 0 : (daysCompleted / totalDays) * 100
  const startDate = dates && moment(dates.split(" to ")[0], "MM/DD/YYYY").format("MMMM D, YYYY")
  const endDate = dates && moment(dates.split(" to ")[1], "MM/DD/YYYY").format("MMMM D, YYYY")

  return (
    <InstructionalDaysDetails
      daysRemaining={daysRemaining}
      endDate={endDate}
      startDate={startDate}
      valueOfGauge={valueOfGauge}
    />
  )
}
