import { apiClient } from "@/api"
import Alerter, { showErrors } from "@/common/alerter"
import { useCallback, useContext, useEffect, useState } from "react"
import { OpeningContext } from "./context"

export const useGetOpeningGroups = id => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [data, setData] = useState([])

  const request = useCallback(async () => {
    // todo - use another endpoint
    const url = `/api/admin/opening_groups.json`
    const params = `school_id=${id}`

    try {
      setLoading(true)
      const { data } = await apiClient.get(`${url}?${params}`)
      setData(data)
    } catch (error) {
      showErrors(error)
    } finally {
      setLoading(false)
      setComplete(true)
    }
  }, [id])

  useEffect(() => {
    if (!id) return
    if (loading || complete) return
    request()
  }, [complete, id, loading, request])

  return { data, loading }
}

export const useUpdateTutorStatus = ({ onSuccess }) => {
  const { urls, tutorList, setTutorList } = useContext(OpeningContext)
  const { change_status_url: url } = urls
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const request = data => {
    setLoading(true)
    setError(false)
    apiClient
      .post(url, data)
      .then(({ data: result }) => {
        const list = [...tutorList]
        const index = list.findIndex(el => el.id === result.id)
        if (index !== -1) {
          list.splice(index, 1, result)
          setTutorList(list)
        }
        if (onSuccess) setTimeout(() => onSuccess(result))
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        showErrors(error)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { loading, error, request }
}

export const useSaveOpeningSchedule = ({ id: tutor_application_id, onSuccess }) => {
  const [loading, setLoading] = useState(false)

  const request = useCallback(
    async timetable => {
      const url = "/api/admin/tutor_applications/timetable_apply"
      try {
        setLoading(true)
        await apiClient.post(url, { tutor_application_id, timetable })
        Alerter.success("Schedule successfully updated")
        if (onSuccess) onSuccess()
      } catch (error) {
        showErrors(error)
      } finally {
        setLoading(false)
      }
    },
    [onSuccess, tutor_application_id]
  )

  return { loading, request }
}

export const useGetTutorApplications = ({ openingId, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const request = useCallback(async () => {
    const url = `/api/admin/tutor_applications/for_opening?opening_id=${openingId}`
    try {
      setLoading(true)
      const { data: result } = await apiClient.get(url)
      setData(result)
      if (onSuccess) onSuccess()
    } catch (error) {
      showErrors(error)
    } finally {
      setLoading(false)
    }
  }, [onSuccess, openingId])

  return { loading, data, request }
}
