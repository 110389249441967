import React from "react"
import { Link } from "react-router-dom"
import { LearningLevel, MeetingFormats } from "../../../../constants"
import { OpeningStatuses } from "../../constants"
import { className as cn } from "../../../../helpers/className"

const cellClasses = "font-weight-medium font-size-small"

export const useColumns = () => {
  return [
    {
      field: "name",
      headerName: "Name",
      type: "custom",
      component: ({ row }) => {
        const link = (
          <Link to={`/${row.id}`}>
            {row.program.name}, {row.school.name}
          </Link>
        )
        return <td className={cellClasses}>{link}</td>
      },
    },
    {
      field: "school.name",
      headerName: "School",
      type: "custom",
      component: ({ row }) => {
        return <td className={cellClasses}>{row.school.name}</td>
      },
    },
    {
      field: "level",
      headerName: "Level",
      type: "custom",
      component: ({ row }) => {
        const level = LearningLevel.find(({ key }) => key === row.learning_level)?.name
        return <td className={cellClasses}>{level}</td>
      },
    },
    {
      field: "location",
      headerName: "Location",
      type: "custom",
      component: ({ row }) => {
        let location
        switch (row.location) {
          case MeetingFormats.Online:
            location = "Online"
            break
          case MeetingFormats.LessonSpace:
            location = "LessonSpace"
            break
          default:
            location = "In Person"
        }
        return <td className={cellClasses}>{location}</td>
      },
    },
    {
      field: "program.name",
      headerName: "Subject",
      type: "string",
      className: cellClasses,
    },
    {
      field: "groups",
      headerName: "Groups",
      type: "custom",
      component: ({ row }) => {
        if (!row.schedules_count) {
          return <td className={cn(cellClasses, "text-gray-chat", "text-no-wrap")}>Not added</td>
        }

        return (
          <td className={cn(cellClasses, "text-no-wrap")}>
            {row.schedules_matched_count} of {row.schedules_count} matched
          </td>
        )
      },
    },
    {
      field: "applications",
      headerName: "Tutors",
      type: "custom",
      component: ({ row }) => {
        return <td className={cn(cellClasses, "text-no-wrap")}>{row.applications_count} applied</td>
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellProps: { align: "right" },
      type: "custom",
      component: ({ row, cellProps }) => {
        let status
        switch (row.status) {
          case OpeningStatuses.Draft:
            status = "Draft"
            break
          case OpeningStatuses.Closed:
            status = "Closed"
            break
          default:
            status = "Active"
        }

        return (
          <td align={cellProps.align} className={cellClasses}>
            {status}
          </td>
        )
      },
    },
  ]
}
