import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { className } from "@/helpers/className"
import useViewport from "@/hooks/useViewport"
import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import CreateOrUpdateForm from "../actions/create_or_update_form"

export const CreateSchoolAdminPageWrapper = ({ children, title, titleAffix }) => {
  const { isMobileViewport } = useViewport()
  return (
    <div className="container p-0 d-flex flex-column flex-grow-1">
      <Row>
        <Col className="p-0">
          <div className="container users-navbar_container -white justify-content-between">
            <h2 className="users-navbar_title m-0">{title}</h2>
            {titleAffix}
          </div>
          <div className="container mt-4 px-0">
            <Row className="mx-n2 px-2 mb-4">
              <Col as="main" xs={24}>
                <Card className={className(isMobileViewport ? "px-3 py-4" : "px-4 py-5")}>{children}</Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const SchoolAdminNewPage = () => {
  const onSubmit = async ({ event, email, firstName, lastName, school, receiveSessions }) => {
    event.preventDefault()

    const school_admin = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      school_id: school,
      receive_session_reports: receiveSessions,
    }

    try {
      await apiClient.post("/api/v2/school_admins", { school_admin })
      Alerter.success("School admin created successfully")
      window.location.replace("/admin/school_admins")
    } catch (error) {
      Alerter.error(error.response.data.error)
    }
  }

  return <CreateOrUpdateForm title={"New School Admin"} onSubmit={onSubmit} />
}
