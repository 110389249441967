import { compose, find, get, isEmpty, keys, map, reduce } from "lodash/fp"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"

import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { getEndOfWeek, getStartOfWeek } from "@/helpers/dates"
import { useFormState } from "react-final-form"
import CouponInfo from "./CouponInfo"
import HourRateInfo from "./HourRateInfo"
import ScheduleCalendar from "./ScheduleCalendar"
import SessionSchedule from "./SessionSchedule"

const getSingleSession = (studentName, date, duration) => {
  if (!duration) {
    return []
  }
  return [
    {
      start: moment(date).set({ s: 0, ms: 0 }).toDate(),
      end: moment(date).set({ s: 0, ms: 0 }).add(duration, "m").toDate(),
      title: `Session with ${studentName}`,
    },
  ]
}

const getWeeklySessions = (studentName, schedule = {}, startedAt) => {
  if (isEmpty(schedule)) {
    return []
  }

  const validSessions = []
  Object.entries(schedule).forEach(([day, { time, duration }]) => {
    if (!time || !duration) {
      return
    }

    const [h, m] = time.split(":")
    const h24 = h === "00" ? "24" : h
    validSessions.push({
      start: moment(startedAt).set({ day, h: h24, m, s: 0, ms: 0 }).toDate(),
      end: moment(startedAt).set({ day, h: h24, m, s: 0, ms: 0 }).add(duration, "m").toDate(),
      title: `Session with ${studentName}`,
    })
  })
  return validSessions
}

const ScheduleStep = ({ parent, timeZone, options, editMode, isParent, urls, hourRate }) => {
  const { values } = useFormState()
  const { recurrence, timetable, duration, student_id } = values

  const [availableIntervals, setAvailableIntervals] = useState([])
  const [startDate, setStartDate] = useState(values.started_at ? new Date(values.started_at) : new Date())

  const studentName = compose(
    get("first_name"),
    find(({ id }) => id === student_id)
  )(parent.students)

  const sessions = recurrence
    ? getWeeklySessions(studentName, timetable, startDate)
    : getSingleSession(studentName, startDate, duration)

  useEffect(() => {
    getAvailability(startDate, recurrence)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (values.started_at) {
      getAvailability(values.started_at, recurrence)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrence])

  useEffect(() => {
    if (values.started_at && !moment(values.started_at).isSame(startDate, "day")) {
      getAvailability(values.started_at, recurrence)
    }
    setStartDate(values.started_at ? new Date(values.started_at) : new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.started_at])

  const getAvailability = async (date, recurrence) => {
    const since = getStartOfWeek(date)
    const till = getEndOfWeek(date)
    try {
      const { data } = await apiClient.get(urls.availability, { params: { since, till, recurrence } })
      const getDayIntervals = date => map(value => ({ date, ...value }))(data[date])
      const arrayData = reduce((result, date) => [...result, ...getDayIntervals(date)], [])(keys(data))
      setAvailableIntervals(arrayData)
    } catch (_) {
      Alerter.error("An error occurred while loading data")
    }
  }

  return (
    <div>
      <h4 className="mb-3">Session schedule</h4>

      <Row>
        <Col lg={18}>
          <SessionSchedule parent={parent} options={options} disabled={!editMode} timeZone={timeZone} />
        </Col>
        <Col lg={6}>
          <HourRateInfo hourRate={hourRate} editMode={editMode} />
          {isParent && parent.active_coupon && parent.coupon_info && (
            <CouponInfo className="mt-4" text={parent.coupon_info.additional_info} />
          )}
        </Col>
      </Row>

      <h4 className="mt-5 mb-3">Tutor availability</h4>
      <p>Review tutor’s availability</p>

      <div className="overflow-hidden">
        <div className="overflow-auto">
          <ScheduleCalendar
            startedAt={startDate}
            sessions={sessions}
            isParent={isParent}
            availableIntervals={availableIntervals}
          />
        </div>
      </div>
    </div>
  )
}

export default ScheduleStep
