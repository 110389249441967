import { apiClient, showAllErrorsInAlert } from "@/api"
import Alerter from "@/common/alerter"
import AsyncAutocompleteField from "@/components/AsyncAutocompleteField"
import FormRow from "@/components/blocks/FormRow"
import SelectWithChips from "@/components/SelectWithChips"
import {
  getSubmitErrors,
  resetArray,
  resetString,
  setFieldData,
  setFieldTouched,
  showFieldError,
} from "@/helpers/forms"
import arrayMutators from "final-form-arrays"
import React, { useCallback, useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { Field, Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"

const Fields = Object.freeze({
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  GRADE: "grade",
  EMAIL: "email",
  SPECIAL_TERMS: "special_treatments",
  ADDITIONAL_EMAILS: "additional_emails",
  SCHOOL_ID: "school_id",
  PARENT: "parent",
  GROUPS: "groups",
})

const defaultValues = {
  [Fields.FIRST_NAME]: "",
  [Fields.LAST_NAME]: "",
  [Fields.GRADE]: "",
  [Fields.EMAIL]: "",
  [Fields.SPECIAL_TERMS]: [],
  [Fields.ADDITIONAL_EMAILS]: "",
  [Fields.SCHOOL_ID]: "",
  [Fields.PARENT]: "",
  [Fields.GROUPS]: [],
}

const validate = formState => {
  const acc = {}
  const firstName = formState[Fields.FIRST_NAME]
  // const email = formState[Fields.EMAIL]
  const grade = formState[Fields.GRADE]
  const school = formState[Fields.SCHOOL_ID]
  const parent = formState[Fields.PARENT]
  if (!firstName) acc[Fields.FIRST_NAME] = "Required field"
  // if (!email) acc[Fields.EMAIL] = "Required field"
  if (!grade) acc[Fields.GRADE] = "Required field"
  if (!school) {
    if (!parent) acc[Fields.PARENT] = "Required field"
  }
  return acc
}

const labelProps = { mb: 7, lg: 5 }
const colProps = { lg: 12 }

const TextField = ({ name, label, placeholder, required }) => {
  return (
    <FormRow LabelProps={{ ...labelProps, htmlFor: name, required }} ColProps={colProps} label={label}>
      <Field type="text" name={name}>
        {({ input, meta }) => (
          <input
            className={`form-control ${meta.touched && meta.error ? "error" : ""}`}
            {...input}
            id={name}
            placeholder={placeholder}
            required={required}
          />
        )}
      </Field>
    </FormRow>
  )
}

const Checkbox = ({ name, value, label, checked, onChange }) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input check_boxes"
        hidden
        checked={checked}
        type="checkbox"
        value={value}
        name={name}
        id={`${name}_${value}`}
        onChange={onChange}
      />
      <label className="collection_check_boxes cursor-pointer" htmlFor={`${name}_${value}`}>
        {label}
      </label>
    </div>
  )
}

const CheckboxGroup = ({ fields, options }) => {
  const isChecked = useCallback(id => (fields.value || []).some(el => el === id), [fields.value])
  const toggle = ({ target: { value } }) => {
    const checked = isChecked(value)
    if (checked) {
      const index = fields.value.findIndex(el => el === value)
      fields.remove(index)
    } else fields.push(value)
  }
  return options.map(([label, value]) => (
    <Checkbox key={value} name={fields.name} label={label} value={value} onChange={toggle} checked={isChecked(value)} />
  ))
}

const SelectField = ({ name, label, elements, required, placeholder, disabled, onChange }) => {
  const change = inputChange => event => {
    const patchedEvent = { ...event, target: { ...event.target, value: event.target.value || null } }
    inputChange(patchedEvent)
    if (onChange) onChange(patchedEvent)
  }

  return (
    <FormRow LabelProps={{ ...labelProps, htmlFor: name, required }} ColProps={colProps} label={label}>
      <Field name={name}>
        {({ input }) => (
          <select
            className="form-control cursor-pointer"
            disabled={disabled}
            required={required}
            aria-required={required}
            {...input}
            onChange={change(input.onChange)}
            id={name}
          >
            <option value="">{placeholder}</option>
            {elements.map(([title, value]) => (
              <option key={value} value={value}>
                {title}
              </option>
            ))}
          </select>
        )}
      </Field>
    </FormRow>
  )
}

const ParentPicker = ({ name, label, disabled, selected }) => {
  return (
    <FormRow LabelProps={{ ...labelProps, htmlFor: name }} ColProps={colProps} label={label}>
      <Field name={name}>
        {({ input, meta }) => (
          <AsyncAutocompleteField
            fieldName={name}
            source="/api/admin/autocomplete/parents"
            className={meta.touched && meta.error ? "error" : ""}
            validationErrors={showFieldError(meta) ? meta.error || meta.submitError : ""}
            selected={selected}
            disabled={disabled}
            onSelect={value => input.onChange({ target: { name, value } })}
          />
        )}
      </Field>
    </FormRow>
  )
}

const CreateOrUpdateUserForm = props => {
  const {
    initialValues,
    onSubmit,
    grades,
    specialTreatments,
    loading,
    schools,
    schoolGroups,
    schoolGroupsLoading,
    updateSchoolGroup,
  } = props

  return (
    <Form
      initialValues={{ ...defaultValues, ...initialValues }}
      validate={validate}
      mutators={{ ...arrayMutators, resetArray, resetString, setFieldData, setFieldTouched }}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Col xl={18}>
            <TextField name={Fields.FIRST_NAME} label="First name" placeholder="Add first name" required />
            <TextField name={Fields.LAST_NAME} label="Last name" placeholder="Add last name" />
            <TextField name={Fields.EMAIL} label="Student Email" placeholder="Add Email" />
            <SelectField name={Fields.GRADE} label="Grade" elements={grades} placeholder="Pick grade" required />
            <FormRow
              LabelProps={{ ...labelProps, as: "legend", className: "pt-0" }}
              ColProps={{ lg: undefined }}
              RowProps={{ as: "fieldset" }}
              label="Learning difference"
            >
              <FieldArray name={Fields.SPECIAL_TERMS} options={specialTreatments} component={CheckboxGroup} />
            </FormRow>
            <SelectField
              name={Fields.SCHOOL_ID}
              label="School"
              elements={schools}
              placeholder="No school"
              onChange={({ target: { value } }) => {
                if (values[Fields.GROUPS]?.length !== 0) form.mutators.resetArray(Fields.GROUPS)
                updateSchoolGroup(value)
              }}
            />
            <ParentPicker name={Fields.PARENT} label="Parent" selected={values[Fields.PARENT]} />
            {values[Fields.SCHOOL_ID] && (
              <SelectWithChips
                loading={schoolGroupsLoading}
                list={schoolGroups}
                name={Fields.GROUPS}
                label="School groups"
                disabled={!values[Fields.SCHOOL_ID]}
                LabelProps={labelProps}
                ColProps={colProps}
                selected={schoolGroups}
                placeholder="Select group to add"
              />
            )}
            <>
              <TextField name={Fields.ADDITIONAL_EMAILS} label="Additional emails" />
              <FormRow
                LabelProps={labelProps}
                ColProps={colProps}
                RowProps={{ style: { marginTop: -24, paddingLeft: 16 } }}
                label=" "
              >
                <small>
                  We will send sessions reports to the emails listed above.
                  <br />
                  Please list additional email addresses, separated by commas.
                  <br />
                </small>
              </FormRow>
            </>
            <div className="col-sm-2">
              <button
                type="submit"
                className="btn btn-outline-primary btn-block"
                data-disable-with="Save"
                disabled={loading || Object.keys(errors).length > 0}
              >
                Save
              </button>
            </div>
          </Col>
        </form>
      )}
    </Form>
  )
}

const useUpdateUser = (url, options) => {
  const { isNew, onSuccess } = { isNew: false, onSuccess: () => {}, ...options }
  const [loading, setLoading] = useState(false)

  const request = useCallback(
    async data => {
      try {
        setLoading(true)
        const { patch, post } = apiClient
        const update = isNew ? post : patch
        const { data: result } = await update(url, data)
        if (onSuccess) onSuccess(result)
        Alerter.success("Student successfully saved")
      } catch (e) {
        showAllErrorsInAlert(e)
        return getSubmitErrors(e)
      } finally {
        setLoading(false)
      }
    },
    [isNew, onSuccess, url]
  )
  return { loading, request }
}

const useGetSchoolsGroupsRequest = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)

  const getRequest = useCallback(
    () => schoolId => {
      setLoading(true)
      apiClient
        .get(`/api/admin/opening_groups.json?school_id=${schoolId}`)
        .then(({ data }) => setList(data.map(({ id, name }) => [name, id])))
        .catch(e => Alerter.error(e?.message))
        .finally(() => setLoading(false))
    },
    []
  )

  return { list, loading, getRequest }
}

const CreateOrUpdateUser = ({ ...props }) => {
  const {
    grades,
    specialTreatments,
    initial,
    schools,
    schoolGroupsList,
    schoolGroupsLoading,
    schoolGroupsRequest,
    userUpdateLoading,
    userUpdateRequest,
  } = props

  const [initialValues, setInitialValues] = useState(() => ({
    [Fields.FIRST_NAME]: initial.first_name,
    [Fields.LAST_NAME]: initial.last_name,
    [Fields.GRADE]: initial.grade,
    [Fields.EMAIL]: initial.email,
    [Fields.SPECIAL_TERMS]: initial.special_treatments,
    [Fields.ADDITIONAL_EMAILS]: initial.additional_emails,
    [Fields.SCHOOL_ID]: initial.school_id,
    [Fields.PARENT]: initial.parent ? { title: initial.parent.name, value: initial.parent.id } : void 0,
    [Fields.GROUPS]: initial.opening_groups,
  }))

  const normalizeData = formData => {
    let result = { ...defaultValues, ...formData }
    result = { ...initialValues, ...result }
    setInitialValues({ ...result })
    const parent = formData[Fields.PARENT]
    const groups = formData[Fields.GROUPS]
    delete result[Fields.PARENT]
    delete result[Fields.GROUPS]
    result.parent_id = parent ? parseInt(parent.value, 10) : null
    result.group_ids = groups
    result[Fields.SCHOOL_ID] = result[Fields.SCHOOL_ID]
      ? parseInt(result[Fields.SCHOOL_ID], 10)
      : result[Fields.SCHOOL_ID]
    userUpdateRequest({ student: result })
  }

  return (
    <CreateOrUpdateUserForm
      initialValues={initialValues}
      onSubmit={normalizeData}
      grades={grades}
      loading={userUpdateLoading}
      specialTreatments={specialTreatments}
      schools={schools}
      schoolGroups={schoolGroupsList}
      schoolGroupsLoading={schoolGroupsLoading}
      updateSchoolGroup={schoolGroupsRequest}
    />
  )
}

const RequestsWrapper = props => {
  const { url, nextUrl, initialValues: initial = {}, isNew } = props
  const { component: Component, ...restProps } = props
  const { school_id: schoolId } = initial
  delete restProps.initialValues

  const { list: schoolGroupsList, loading: schoolGroupsLoading, getRequest } = useGetSchoolsGroupsRequest()

  const { loading: userUpdateLoading, request: userUpdateRequest } = useUpdateUser(url, {
    isNew,
    onSuccess: ({ id }) => {
      if (nextUrl) window.location.assign(isNew ? `${nextUrl}/${id}` : nextUrl)
    },
  })

  useEffect(() => {
    const request = getRequest()

    if (schoolId) request(schoolId)
  }, [getRequest, schoolId])

  const data = {
    schoolGroupsList,
    schoolGroupsLoading,
    schoolGroupsRequest: getRequest(),
    userUpdateLoading,
    userUpdateRequest,
    initial,
  }

  return <Component {...restProps} {...data} />
}

const CreateOrUpdateUserWrapper = params => {
  return <RequestsWrapper {...params} component={CreateOrUpdateUser} />
}

export default CreateOrUpdateUserWrapper
