import axios from "axios"
import { apiConfig } from "../config/apiConfig"

/**
 * Creates and returns a new instance of an Axios client configured with custom settings.
 *
 * This function utilizes the axios library to create a new HTTP client instance
 * with pre-configured settings defined in the apiConfig object. The resulting
 * client can be used to make API requests with consistent configuration across
 * the application.
 *
 * @returns {AxiosInstance} A new Axios instance configured with custom settings
 *                          from apiConfig, ready to make HTTP requests.
 */
export const createApiClient = () => {
  return axios.create(apiConfig)
}
