import { apiClient } from "@/api"
import BraintrustDataGrid from "@/common/DataGrid/index"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import PostAddIcon from "@mui/icons-material/PostAdd"
import { Box, IconButton, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { INITIAL_PAGINATION, MAX_SELECTED_STUDENTS } from "./constants"
import StudentsDataGridToolbar from "./studentsDataGridToolbar"
import { transformFiltersForAPI, transformStudentData } from "./utils"

const NameLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}))

const fetchStudents = async (studentsAPIEndpoint, options = {}) => {
  const { pageSize, pageNumber, paginate, filters = {}, sorting } = options
  const transformedFilters = transformFiltersForAPI(filters)
  const sortBy = sorting[0]?.field || "name"
  const sortOrder = sorting[0]?.sort || "asc"

  const { data } = await apiClient.get(studentsAPIEndpoint, {
    params: {
      per_page: pageSize,
      page: pageNumber + 1,
      paginate,
      ...transformedFilters,
      sort_by: sortBy,
      sort_order: sortOrder,
    },
  })

  const { students, per_page, total_count, page } = data
  return {
    students,
    totalCount: Number(total_count),
    perPage: Number(per_page),
    page: Number(page) - 1,
  }
}

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: params => <NameLink href={`/admin/students/${params.row.id}`}>{params.row.name}</NameLink>,
  },
  { field: "grade", headerName: "Grade", flex: 0.5, filterable: true },
  { field: "school", headerName: "School", flex: 1, filterable: true },
  { field: "parent", headerName: "Parent", flex: 1, filterable: true },
  { field: "groups", headerName: "Groups", flex: 1, filterable: true, type: "string" },
  { field: "assignments", headerName: "Assignments", flex: 0.5, filterable: true, type: "number" },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    filterable: true,
    align: "center",
    headerAlign: "center",
    renderCell: params => (
      <Tooltip title={params.value === "active" ? "Active" : "Inactive"}>
        {params.value === "active" ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
      </Tooltip>
    ),
    valueOptions: ["active", "inactive"],
  },
  {
    field: "new_session_link",
    flex: 0.5,
    sortable: false,
    align: "center",
    type: "actions",
    renderCell: params => (
      <Tooltip title="Create a session">
        <IconButton color="primary" size="small" component="a" href={params.value}>
          <PostAddIcon />
        </IconButton>
      </Tooltip>
    ),
  },
]

const StudentsTable = ({ apiRef, setStudentsToPrint, rowSelectionModel, handleRowSelectionModelChange }) => {
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [data, setData] = useState({ students: [], totalCount: 0, perPage: 50, page: 0 })
  const [status, setStatus] = useState({ isLoading: true, error: null })
  const [paginationModel, setPaginationModel] = useState(INITIAL_PAGINATION)
  const [density, setDensity] = useState("standard")
  const [sortModel, setSortModel] = useState([{ field: "name", sort: "asc" }])
  const [filterModel, setFilterModel] = useState({ items: [], logicOperator: "and" })

  const isSelectAllDisabled = useMemo(
    () => data.totalCount > MAX_SELECTED_STUDENTS || allRowsSelected,
    [allRowsSelected, data.totalCount]
  )

  const handlePageModelChange = useCallback(newModel => setPaginationModel(prev => ({ ...prev, ...newModel })), [])
  const handleSortModelChange = useCallback(newSortModel => setSortModel(newSortModel), [])
  const handleDensityChange = useCallback(newDensity => setDensity(newDensity), [])
  const handleFilterModelChange = useCallback(newModel => {
    setFilterModel(newModel)
    setPaginationModel(INITIAL_PAGINATION)
  }, [])

  const handleResetFilters = useCallback(() => {
    setFilterModel({ items: [], logicOperator: "and" })
    setPaginationModel(INITIAL_PAGINATION)
  }, [])

  const handleSelectAll = useCallback(() => {
    setAllRowsSelected(true)
    setFilterModel(prevFilterModel => ({
      ...prevFilterModel,
      items: [
        ...prevFilterModel.items.filter(item => item.field !== "statuses"),
        { field: "statuses", operator: "equals", value: "active" },
      ],
    }))
  }, [])

  const handleUnselectAll = useCallback(() => {
    setAllRowsSelected(false)
    handleRowSelectionModelChange([])
  }, [handleRowSelectionModelChange])

  const requestOptions = useMemo(
    () => ({
      pageSize: allRowsSelected ? null : paginationModel.pageSize,
      pageNumber: allRowsSelected ? 0 : paginationModel.page,
      paginate: !allRowsSelected,
      sorting: sortModel,
      filters: filterModel,
    }),
    [allRowsSelected, filterModel, paginationModel, sortModel]
  )

  const fetchData = useCallback(async () => {
    setStatus(prev => ({ ...prev, isLoading: true }))
    try {
      const result = await fetchStudents("/api/v2/admins/students", requestOptions)
      setData(result)
    } catch (error) {
      setStatus({ isLoading: false, error })
    } finally {
      setStatus(prev => ({ ...prev, isLoading: false }))
    }
  }, [requestOptions])

  const fetchAllData = useCallback(async () => {
    try {
      const result = await fetchStudents("/api/v2/admins/students", requestOptions)
      const allIds = result.students.map(student => student.id)
      handleRowSelectionModelChange(allIds)
      setStudentsToPrint(transformStudentData(result.students))
    } catch (error) {
      setStatus({ isLoading: false, error })
    } finally {
      setStatus(prev => ({ ...prev, isLoading: false }))
    }
  }, [handleRowSelectionModelChange, requestOptions, setStudentsToPrint])

  useEffect(() => {
    if (allRowsSelected) {
      fetchAllData()
    } else {
      fetchData()
    }
  }, [allRowsSelected, fetchAllData, fetchData])

  const rows = useMemo(() => transformStudentData(data.students), [data.students])

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <BraintrustDataGrid
        apiRef={apiRef}
        rows={rows}
        rowCount={data.totalCount}
        columns={columns}
        loading={status.isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePageModelChange}
        onFilterModelChange={handleFilterModelChange}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        density={density}
        onDensityChange={handleDensityChange}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        slots={{ toolbar: StudentsDataGridToolbar }}
        slotProps={{
          toolbar: {
            filterModel,
            onFilterModelChange: handleFilterModelChange,
            onResetFilters: handleResetFilters,
            rowSelectionModel,
            isSelectAllDisabled,
            allRowsSelected,
            handleSelectAll,
            handleUnselectAll,
            status,
          },
        }}
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection
        keepNonExistentRowsSelected
        autoHeight
      />
    </Box>
  )
}

export default StudentsTable
