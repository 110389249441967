const isServer = typeof window === "undefined"

export const windowObj = isServer
  ? {
      location: { search: "", href: "" },
      addEventListener: () => {},
      removeEventListener: () => {},
    }
  : window

export const documentObj = isServer
  ? {
      content: "",
      querySelector: () => null,
    }
  : document

export default {
  window: windowObj,
  document: documentObj,
}
