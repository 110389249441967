import { every, filter, isEmpty } from "lodash/fp"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Card, Container } from "react-bootstrap"
import { Field, Form } from "react-final-form"

import { apiClient } from "@/api"
import { getQueryStringOmitParams, limit } from "@/common/searchOptions"
import { clearFieldData, clearFieldsData, setFieldData } from "@/helpers/forms"
import { programsHierarchyToList } from "@/helpers/programsHierarchy"
import { serializeGradesFromHierarchy, serializeSpecialTreatments } from "@/helpers/serializers"
import { chain, required, validateZip } from "@/helpers/validators"
import BasePagination from "../../blocks/BasePagination"
import Footer from "../../blocks/Footer"
import IndeterminateProgressBar from "../../blocks/IndeterminateProgressBar"
import {
  formValuesToSearchParams,
  getQueryStringFromParams,
  getSearchParamsFromUrl,
  searchParamsToFormValues,
} from "../serializeParams"
import TutorsList from "../TutorsList"
import QuestionnaireWizard from "../wizard"
import Filter from "./Filter"
import Header from "./Header"
import schema from "./schema"

const FILTER_FIELD_NAMES = [
  "grade",
  "program_ids",
  "struggle_with",
  "special_treatments",
  "executive_function",
  "online",
  "zip",
  "max_price",
  "page",
  "limit",
]

const Navbar = () => (
  <nav className="users-navbar mb-0">
    <div className="users-navbar_container container flex-column align-items-start">
      <h1 className="users-navbar_title">Let us help you find the perfect tutoring match!</h1>
      <p className="font-weight-normal mt-2 mb-3">
        Choose from the filters below so we can learn more about your child as a learner.
      </p>
    </div>
  </nav>
)

const validate = values => ({
  zip: values.offline ? chain(required, validateZip)(values.zip) : undefined,
})

const isFilterEmpty = values => every(({ completed }) => !completed(values))(schema)

const shouldShowWizard = () => {
  const searchParams = Object.keys(getSearchParamsFromUrl(["zip"]))
  const filteredSearchParams = filter(param => FILTER_FIELD_NAMES.includes(param))(searchParams)
  return isEmpty(filteredSearchParams)
}

const FindTutor = ({ urls, dataSources }) => {
  const queryString = getQueryStringOmitParams(["page", "limit"], ["zip"])
  const cardRef = useRef()

  const [showWizard, setShowWizard] = useState(shouldShowWizard())
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({})
  const [formValues, setFormValues] = useState({})
  const [tutors, setTutors] = useState(dataSources.tutors && dataSources.tutors.tutors ? dataSources.tutors.tutors : [])
  const [tutorsCount, setTutorsCount] = useState(
    dataSources.tutors && dataSources.tutors.count ? dataSources.tutors.count : 0
  )

  const { find: findTutorUrl, sign_up: signUpUrl, sign_in: signInUrl, current_url: currentUrl } = urls
  const programs = useMemo(() => programsHierarchyToList(dataSources.hierarchy), [dataSources])

  const options = useMemo(
    () => ({
      hierarchy: dataSources.hierarchy,
      programs: programsHierarchyToList(dataSources.hierarchy),
      struggleWithQuestions: dataSources.struggleWithQuestions,
      grades: serializeGradesFromHierarchy(dataSources.hierarchy),
      specialTreatments: serializeSpecialTreatments(dataSources.specialTreatments),
    }),
    [dataSources]
  )

  useEffect(() => {
    const { page = 1, ...params } = getSearchParamsFromUrl(["zip"])
    setSearchParams({ ...params, page, limit })

    const initialFormValues = searchParamsToFormValues({ ...params, page }, programs)
    setFormValues(initialFormValues)

    if (dataSources.tutors) {
      const { tutors, count } = dataSources.tutors
      setTutors(tutors)
      setTutorsCount(count)
    } else {
      findTutors(findTutorUrl, { ...params, page, limit })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const findTutors = async (url, params) => {
    setIsLoading(true)
    const queryString = getQueryStringFromParams(params)
    try {
      const {
        data: { tutors, count },
      } = await apiClient.get(url + queryString)
      setTutors(tutors)
      setTutorsCount(count)
    } catch (_) {
      setTutors([])
      setTutorsCount(0)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = values => {
    setFormValues(values)
    const isPageChanged = searchParams.page !== values.page
    const newSearchParams = formValuesToSearchParams(
      { limit, ...values, page: isPageChanged ? values.page : 1 },
      programs
    )
    setSearchParams(newSearchParams)
    findTutors(findTutorUrl, newSearchParams)
    const queryString = getQueryStringFromParams(newSearchParams)
    window?.history.replaceState(null, null, queryString)
  }

  const scrollToTop = () => {
    cardRef.current.scrollIntoView("start")
  }

  if (showWizard) {
    return (
      <Form initialValues={formValues} mutators={{ setFieldData }} onSubmit={onSubmit}>
        {({ form }) => (
          <QuestionnaireWizard
            options={options}
            onSubmit={() => {
              setShowWizard(false)
              form.submit()
            }}
          />
        )}
      </Form>
    )
  }

  return (
    <Form
      initialValues={formValues}
      mutators={{ setFieldData, clearFieldData, clearFieldsData }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ values, form }) => (
        <>
          {!dataSources.authorized && <Header urls={{ signInUrl, signUpUrl: signUpUrl + queryString }} />}
          <Navbar />
          <div className="flex-grow-1 panel-wrapper">
            <Container>
              <Card className="mb-5" ref={cardRef}>
                <Card.Header>
                  <Filter values={formValues} options={options} urls={urls} />
                </Card.Header>
                {isLoading && <IndeterminateProgressBar />}
                <Card.Body>
                  {!isLoading && (
                    <Field name="order_by">
                      {({ input }) => (
                        <TutorsList
                          sort={input.value}
                          page={values.page}
                          tutors={tutors}
                          count={tutorsCount}
                          options={options}
                          onSortChanged={value => {
                            input.onChange(value)
                            form.submit()
                            scrollToTop()
                          }}
                          shouldSplitResults={!isFilterEmpty(values)}
                        />
                      )}
                    </Field>
                  )}
                  {!isLoading && (
                    <Field name="page">
                      {({ input }) => (
                        <BasePagination
                          currentPage={input.value}
                          limit={limit}
                          count={tutorsCount}
                          currentUrl={currentUrl}
                          onPageChanged={value => {
                            document.title = `Find a Certified, Verified Tutor | Braintrust${
                              value > 1 ? ". Page: " + value : ""
                            }`
                            input.onChange(value)
                            form.submit()
                            scrollToTop()
                          }}
                        />
                      )}
                    </Field>
                  )}
                </Card.Body>
              </Card>
            </Container>
          </div>
          <Footer signUpUrl={signUpUrl + queryString} />
        </>
      )}
    </Form>
  )
}

export default FindTutor
