import React, { useState } from "react"
import { Form } from "react-final-form"

import { apiClient, showAllErrorsInAlert } from "@/api"
import FormFields from "./FormFields"

const Company = ({ dataSources, admin }) => {
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)

  const onSubmit = values => {
    apiClient
      .put(dataSources.urls.save, values)
      .then(() => window.location.replace(dataSources.urls.next))
      .catch(showAllErrorsInAlert)
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, form }) => (
        <div className="container">
          <div className="row mb-2">
            <h4 className="col-sm-24">Company connection</h4>
            <p className="col-sm-24">Please fill in tutor&apos;s company settings</p>
          </div>

          <FormFields
            dataSources={dataSources}
            isLoadingPhoto={isLoadingPhoto}
            setIsLoadingPhoto={setIsLoadingPhoto}
            admin={admin}
            form={form}
          />

          <button
            type="button"
            className="btn btn-outline-primary mt-4"
            onClick={handleSubmit}
            disabled={isLoadingPhoto || submitting}
          >
            Save
          </button>
        </div>
      )}
    </Form>
  )
}

export default Company
