import { find, map, reject } from "lodash/fp"
import React, { useEffect, useMemo, useState } from "react"

import { apiClient, replaceId, showAllErrorsInAlert } from "@/api"
import Alerter from "@/common/alerter"
import { REQUESTED_INTERVIEW } from "@/constants"
import IndeterminateProgressBar from "../../blocks/IndeterminateProgressBar"
import useFocusedInterview from "../useFocusedInterview"
import ConfirmRequestedInterviewModal from "./ConfirmRequestedInterviewModal"
import InterviewDeclineModal from "./InterviewDeclineModal"
import TutorRequestedInterviewCard from "./TutorRequestedInterviewCard"

const Interviews = ({ urls }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [interviews, setInterviews] = useState([])
  const [interviewIdToConfirm, setInterviewIdToConfirm] = useState(null)
  const [interviewIdToDecline, setInterviewIdToDecline] = useState(null)

  const focusedInterviewId = useFocusedInterview()

  const currentInterview = useMemo(() => {
    if (interviewIdToConfirm) {
      return find(["id", interviewIdToConfirm])(interviews)
    }
    return null
  }, [interviewIdToConfirm, interviews])

  useEffect(() => {
    getInterviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInterviews = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(urls.get, {
        params: { filter: REQUESTED_INTERVIEW },
      })
      setInterviews(data)
    } catch (e) {
      Alerter.error("An error occurred while loading data")
    } finally {
      setIsLoading(false)
    }
  }

  const onInterviewConfirm = async (id, values) => {
    try {
      await apiClient.put(replaceId(urls.accept, id), values)
      setInterviews(prevInterviews => reject(["id", id])(prevInterviews))
      setInterviewIdToConfirm(null)
      Alerter.success("Interview was successfully confirmed")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const onInterviewEdit = async (id, values) => {
    try {
      const { data } = await apiClient.put(`${urls.get}/${id}`, values)
      setInterviews(prevInterviews => map(item => (item.id === id ? data : item))(prevInterviews))
      setInterviewIdToConfirm(null)
      Alerter.success("Suggested time has been sent to parent")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const onInterviewDecline = async (id, values) => {
    try {
      await apiClient.delete(replaceId(urls.decline, id), { data: values })
      setInterviewIdToDecline(null)
      setInterviews(prevInterviews => reject(["id", id])(prevInterviews))
      Alerter.success("Interview was successfully declined")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  return (
    <>
      {isLoading && <IndeterminateProgressBar />}
      <div className="p-4 p-md-5">
        {!isLoading && !interviews.length && <p className="text-center m-0">No interviews found</p>}
        {!isLoading &&
          interviews.length > 0 &&
          interviews.map(interview => (
            <TutorRequestedInterviewCard
              key={interview.id}
              interview={interview}
              saveMessageUrl={urls.save_message}
              enableMessageButtonUrl={urls.enable_message_button}
              focused={focusedInterviewId === interview.id}
              onDecline={() => setInterviewIdToDecline(interview.id)}
              onConfirm={() => setInterviewIdToConfirm(interview.id)}
            />
          ))}
      </div>

      {currentInterview && (
        <ConfirmRequestedInterviewModal
          show={true}
          interview={currentInterview}
          onConfirm={values => onInterviewConfirm(currentInterview.id, values)}
          onEdit={values => onInterviewEdit(currentInterview.id, values)}
          onClose={() => setInterviewIdToConfirm(null)}
        />
      )}
      {interviewIdToDecline && (
        <InterviewDeclineModal
          show={true}
          onSubmit={values => onInterviewDecline(interviewIdToDecline, values)}
          onClose={() => setInterviewIdToDecline(null)}
        />
      )}
    </>
  )
}

export default Interviews
