import { windowObj as window } from "@/common/serverSideRendering"
import { MEDIA_LG_BREAKPOINT, MEDIA_MD_BREAKPOINT, MEDIA_SM_BREAKPOINT } from "@/constants"
import { useEffect, useState } from "react"

export default () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return {
    width,
    isPhoneViewport: width < MEDIA_SM_BREAKPOINT,
    isTabletViewport: width >= MEDIA_SM_BREAKPOINT && width < MEDIA_MD_BREAKPOINT,
    isLargeTabletViewport: width >= MEDIA_SM_BREAKPOINT && width < MEDIA_LG_BREAKPOINT,
    isMobileViewport: width < MEDIA_LG_BREAKPOINT,
  }
}
