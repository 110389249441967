import Logo from "@/assets/images/brt-logo-black.png"
import { BRT_QRCODE_URL } from "@/constants"
import React, { memo } from "react"
import { Card } from "react-bootstrap"
import QRCode from "react-qr-code"

/**
 * StudentLoginCard component renders a card with student information and a QR code.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.item - The student data object.
 * @param {string} props.item.name - The name of the student.
 * @param {string} props.item.email - The email of the student.
 * @param {string} props.item.groups - The groups the student belongs to.
 * @param {string} props.item.password - The password of the student.
 *
 * @returns {JSX.Element} The rendered StudentLoginCard component.
 */
const StudentLoginCard = ({ item }) => {
  if (!item) return null

  return (
    <Card
      className="shadow-none rounded-0 student-card"
      style={{
        width: "100mm",
      }}
    >
      <Card.Body className="p-4 w-100 h-100">
        <Card.Title className="student-card-header">
          <QRCodeSection student={item} />
          <img src={Logo} alt="Braintrust Logo" className="student-card-logo" />
        </Card.Title>
        <Card.Text as="div">
          <StudentInfo label="Student name:" value={item.name} />
          <StudentInfo label="Group name:" value={item.groups} />
          <StudentInfo label="Email:" value={item.email} />
          <StudentInfo label="Password:" value={item.password} />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default StudentLoginCard

const QRCodeSection = ({ student }) => (
  <div className="d-flex align-items-center w-100 pt-3">
    <QRCode value={BRT_QRCODE_URL} size={30} />
    <p className="student-card-qrcode-description">
      Go to{" "}
      <a href={BRT_QRCODE_URL} target="_blank" rel="noopener noreferrer" className="student-card-link">
        btstudent.com
      </a>{" "}
      and
      <br />
      <span>
        {Boolean(student.google_sso_enabled) && (
          <>
            click on <i>`Sign in with Google`</i> <strong>OR</strong>
            <br />
          </>
        )}
        type your Email and Password
      </span>
    </p>
  </div>
)

/**
 * StudentInfo component displays a label and its corresponding value.
 *
 * @component
 * @example
 * const label = "Name"
 * const value = "John Doe"
 * return <StudentInfo label={label} value={value} />
 *
 * @param {Object} props - Component props
 * @param {string} props.label - The label to display
 * @param {string} props.value - The value to display
 * @returns {JSX.Element} The rendered component
 */
export const StudentInfo = memo(({ label, value }) => (
  <div className="d-flex flex-column student-info">
    <div className="student-info-label">{label}</div>
    <div className="font-weight-bold student-info-value">{value}</div>
  </div>
))

StudentInfo.displayName = "StudentInfo"
