import { Chip } from "@mui/material"
import { styled } from "@mui/material/styles"

export const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  "& .MuiChip-label": {
    fontWeight: 500,
    textTransform: "capitalize",
  },
}))

export default FilterChip
