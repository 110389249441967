import cn from "classnames"
import React, { useState } from "react"
import { Alert, Button, FormControl } from "react-bootstrap"

import { apiClient, showAllErrorsInAlert } from "@/api"

const CouponInput = ({ className, onValidationSucceded, ...props }) => {
  const [value, setValue] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const validateCoupon = async () => {
    setErrorMessage("")
    setIsLoading(true)
    try {
      const { data } = await apiClient.get("/api/coupons/validate", { params: { code: value } })
      if (data.active) {
        if (onValidationSucceded) {
          await onValidationSucceded({ response: data, code: value })
        }
        setSuccessMessage(`Code activated. ${data.coupon_info.additional_info || ""}`)
      } else {
        setErrorMessage("Invalid code")
      }
    } catch (e) {
      showAllErrorsInAlert(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onValueChange = e => {
    setErrorMessage("")
    setValue(e.target.value)
  }

  const onKeyDown = e => {
    if (e.key === "Enter" && value) {
      validateCoupon()
    }
  }

  return (
    <div className={cn("coupon-input", className)}>
      <div className="coupon-input_control-wrapper">
        <FormControl
          {...props}
          value={value}
          disabled={isLoading || successMessage}
          className={cn("coupon-input_control", successMessage && "-success")}
          onChange={onValueChange}
          onKeyDown={onKeyDown}
        />
        {successMessage && <input type="hidden" name="coupon_code" value={value} />}
        {value && !successMessage && (
          <div className="coupon-input_button">
            <Button
              type="button"
              variant="link"
              size="sm"
              className="btn-link-primary"
              disabled={isLoading}
              onClick={validateCoupon}
            >
              Apply
            </Button>
          </div>
        )}
        {successMessage && <div className="coupon-input_success-mark">✓</div>}
      </div>
      {successMessage && (
        <Alert variant="green" className="coupon-input_message">
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert variant="danger" className="coupon-input_message">
          {errorMessage}
        </Alert>
      )}
    </div>
  )
}

export default CouponInput
