import { showAllErrorsInAlert } from "@/api"
import React, { useCallback, useState } from "react"
import Autosuggest from "react-autosuggest"

const AUTOCOMPLETE_MIN_SYMBOLS = 3

const SchoolSelect = ({ initValue, placeholder, className, onChange, source, ...restProps }) => {
  const [value, setValue] = useState(initValue)
  const [suggestions, setSuggestions] = useState([])

  const loadSuggestions = async value => {
    if (value.length >= AUTOCOMPLETE_MIN_SYMBOLS) {
      try {
        const response = await fetch(`${source}?q=${value}`)
        const schools = await response.json()
        setSuggestions(schools.items)
      } catch (e) {
        showAllErrorsInAlert(e)
        setSuggestions([])
      }
    } else {
      setSuggestions([])
    }
  }

  const debouncedLoadSuggestions = useCallback(_.debounce(loadSuggestions, 300), []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSuggestionValue = suggestion => {
    return suggestion.title
  }

  const renderSuggestion = suggestion => {
    return <span>{suggestion.title}</span>
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions(value)
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const onInputChange = (event, { newValue }) => {
    setValue(newValue)
    onChange(newValue)
  }

  const inputProps = {
    placeholder,
    value,
    className,
    onChange: onInputChange,
    "data-test": "employment_school",
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      {...restProps}
    />
  )
}

export default SchoolSelect
