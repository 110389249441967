import React, { useState } from "react"

import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import { DirectUpload } from "@rails/activestorage"

import { apiClient } from "@/api"
import withAlerter from "../common/withAlerter"

const StyledDropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:focus {
    border-color: #2196f3;
  }
  &.disabled {
    opacity: 0.6;
  }
  & > p {
    margin: 0;
  }
`
const StyledRemoveLink = styled.a`
  color: #dc3545;
  font-size: 0.85714rem;
  text-transform: uppercase;
  font-weight: bold;
`
const StyledRemoveBadge = styled.span`
  margin-right: 5px;
`

const FileUploading = () => {
  return <span className="badge badge-info">Uploading</span>
}

const FileUploaded = withAlerter(({ file, isRemoving, onRemove, uploadEnabled }) => {
  const removeFile = async event => {
    event.preventDefault()

    onRemove(file)
  }

  return (
    <>
      {isRemoving && <StyledRemoveBadge className="badge badge-danger">Removing...</StyledRemoveBadge>}
      {file.download_link && !isRemoving ? <a href={file.download_link}>{file.filename}</a> : file.filename}

      {uploadEnabled && !isRemoving && (
        <StyledRemoveLink href="#" className="float-right" onClick={e => removeFile(e)}>
          remove
        </StyledRemoveLink>
      )}
    </>
  )
})

const FileByState = ({ file, isRemoving, onRemove, uploadEnabled }) => {
  if (file instanceof File) {
    return <FileUploading file={file} />
  } else if (file.id) {
    return <FileUploaded file={file} isRemoving={isRemoving} onRemove={onRemove} uploadEnabled={uploadEnabled} />
  } else {
    throw "Wrong file record type"
  }
}

const Files = ({ files, removedFiles, onRemove, uploadEnabled }) => {
  return (
    <>
      <ul className="list-group">
        {files.map((file, index) => (
          <li className="list-group-item clearfix" key={index}>
            <FileByState
              file={file}
              isRemoving={removedFiles.indexOf(file) !== -1}
              onRemove={onRemove}
              uploadEnabled={uploadEnabled}
            />
          </li>
        ))}
      </ul>
    </>
  )
}

export const UploadFileField = withAlerter(
  ({ alerter, files = [], setFiles, directUploadUrl, submitUrl, removeUrl, onChange, uploadEnabled = true }) => {
    const removeFile = file => setFiles(ffs => ffs.filter(f => file !== f))
    const [removedFiles, setRemovedFiles] = useState([])

    const onDrop = async acceptedFiles => {
      const list = acceptedFiles.map(f => {
        let file = f
        if (!(f instanceof File)) return
        const { size, type, name } = f
        const blob = f.slice(0, size, type)
        const getFileIndex = name => files.findIndex(({ filename }) => filename === name)
        let counter = 0
        let index = getFileIndex(name)
        while (index !== -1) {
          counter++
          let fileName = name.split(".")
          const ext = fileName.pop()
          if (fileName.length === 0) fileName = [`${ext}(${counter})`]
          else {
            const prev = fileName.pop()
            fileName = [...fileName, `${prev}(${counter})`, ext]
          }
          index = getFileIndex(fileName.join("."))
          if (index === -1) file = new File([blob], fileName.join("."), { type: type })
        }
        return file
      })

      setFiles(ffs => ffs.concat(list))

      const promises = list
        .map(file => {
          const upload = new DirectUpload(file, directUploadUrl)
          upload.create((error, blob) => {
            if (error) {
              alerter.error(`UploadFile network/server error when uploading file ${file.name}`)
              return null
            } else {
              return apiClient
                .post(submitUrl, { blob: blob })
                .then(({ data: { attachment } }) => {
                  setFiles(ffs => ffs.map(f => (file === f ? attachment : f)))
                })
                .catch(() => {
                  alerter.error(`UploadFile network/server error when attaching file ${file.name}`)
                  removeFile(file)
                })
                .finally(() => {
                  if (onChange) onChange()
                })
            }
          })
        })
        .filter(Boolean)

      await Promise.all(promises)
    }

    const onRemove = async file => {
      setRemovedFiles([...removedFiles, file])
      await apiClient
        .delete(removeUrl, { data: { attachment: file } })
        .then(async () => {
          setRemovedFiles(removedFiles.filter(f => file !== f))
          removeFile(file)
          if (onChange) await onChange()
        })
        .catch(() => {
          alerter.error(`UploadFile network/server error when deleting attachment ${file.filename}`)
        })
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
      <section className="container">
        {uploadEnabled && (
          <StyledDropzone {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>{`Drag'n'drop some files here, or click to select files`}</p>
            )}
          </StyledDropzone>
        )}

        <aside style={{ marginTop: "1em" }}>
          <Files files={files} removedFiles={removedFiles} onRemove={onRemove} uploadEnabled={uploadEnabled} />
        </aside>
      </section>
    )
  }
)

export const StatefulUploadFileField = ({ files, ...rest }) => {
  const [filesToDisplay, setFilesToDisplay] = useState(files || [])
  return <UploadFileField files={filesToDisplay} setFiles={setFilesToDisplay} {...rest} />
}

export default StatefulUploadFileField
