import { TextField } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
      color: theme.palette.primary.main,
    },
    "& .Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },

    "& .Mui-focused.MuiSvgIcon-root.MuiSvgIcon-colorAction": {
      color: theme.palette.primary.main,
    },
  },
}))

export default StyledTextField
