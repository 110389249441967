import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { useCallback, useRef, useState } from "react"

export const useSaveOpeningRequest = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const success = useRef(false)

  const request = data => {
    setLoading(true)
    success.current = false
    apiClient
      .post("/admin/openings", data)
      .then(() => {
        Alerter.success("Opening successfully created")
        success.current = true
        if (onSuccess) onSuccess()
      })
      .catch(({ response: { data }, message }) => {
        // eslint-disable-next-line no-console
        console.error(message)
        for (const key of Object.keys(data.errors)) {
          const value = data.errors[key]
          for (const error of value) {
            Alerter.error(`${key}: ${error}`)
          }
        }
      })
      .finally(() => {
        success.current ? setTimeout(() => setLoading(false), 1000) : setLoading(false)
      })
  }

  return { loading, request }
}

export const useOpeningGroups = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const request = useCallback(async schoolId => {
    const url = `/api/admin/opening_groups.json?school_id=${schoolId}`
    try {
      setLoading(true)
      const { data: result } = await apiClient.get(url)
      setData(result)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      Alerter.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { loading, data, request }
}
