import { isEmpty, keys, map, reduce } from "lodash/fp"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Alert, Button, Col, FormControl, Row } from "react-bootstrap"
import { Field, Form } from "react-final-form"

import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { formatDate, getEndOfWeek, getStartOfWeek } from "@/helpers/dates"
import { notEmptyArray } from "@/helpers/validators"
import { ReactComponent as ChevronNext } from "../../../../assets/images/icons/chevron-next.svg"
import { ReactComponent as ChevronPrev } from "../../../../assets/images/icons/chevron-prev.svg"
import TutorCard from "../../find_tutor/tutor_card/TutorCard"
import InterviewCalendar from "./InterviewCalendar"
import InterviewSlot from "./InterviewSlot"

const getPeriodMonth = ({ start, end }) => {
  const momentStart = moment(start)
  const momentEnd = moment(end)
  if (momentStart.isSame(momentEnd, "month")) {
    return momentStart.format("MMMM")
  }
  return `${momentStart.format("MMMM")}/${momentEnd.format("MMMM")}`
}

const InterviewForm = ({
  initialValues = {},
  editMode,
  isParent,
  tutor,
  urls,
  tutorTimeZone,
  options,
  onSubmit,
  onClose,
}) => {
  const [availableIntervals, setAvailableIntervals] = useState([])
  const [isLoadingAvailability, setIsLoadingAvailability] = useState(false)
  const [calendarPeriod, setCalendarPeriod] = useState({
    start: getStartOfWeek(new Date()),
    end: getEndOfWeek(new Date()),
  })

  useEffect(() => {
    getAvailability(calendarPeriod)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarPeriod])

  const getAvailability = async ({ start: since, end: till }) => {
    setIsLoadingAvailability(true)
    try {
      const { data } = await apiClient.get(urls.availability, { params: { since, till } })
      const getDayIntervals = date => map(value => ({ date, ...value }))(data[date])
      const arrayData = reduce((result, date) => [...result, ...getDayIntervals(date)], [])(keys(data))
      setAvailableIntervals(arrayData)
    } catch (_) {
      Alerter.error("An error occurred while loading data")
    } finally {
      setIsLoadingAvailability(false)
    }
  }

  const prev = () => {
    setCalendarPeriod(({ start, end }) => ({
      start: formatDate(moment(start).subtract(1, "w")),
      end: formatDate(moment(end).subtract(1, "w")),
    }))
  }

  const next = () => {
    setCalendarPeriod(({ start, end }) => ({
      start: formatDate(moment(start).add(1, "w")),
      end: formatDate(moment(end).add(1, "w")),
    }))
  }

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, invalid, submitting, handleSubmit }) => (
        <>
          <div className="p-4">
            <p>Want to make sure this tutor is a great fit for your family? Schedule a free video interview!</p>

            <TutorCard tutor={tutor} options={options} isLink={false} />

            <div className="d-flex align-items-center justify-content-between mt-5 mb-3">
              <h4 className="m-0">Tutor availability - {getPeriodMonth(calendarPeriod)}</h4>
              <div className="d-flex align-items-center">
                <Button variant="outline-primary" className="btn-circle" onClick={prev}>
                  <ChevronPrev />
                </Button>
                <Button variant="outline-primary" className="btn-circle ml-3" onClick={next}>
                  <ChevronNext />
                </Button>
              </div>
            </div>
            <Alert variant="info" className="text-center mb-4">
              Choose 1-3 time slots from the tutor’s calendar that are a good fit for your schedule.
              <br /> If you need to edit your selection, just click again on the same time to remove it from the
              calendar.
              <br /> You’ll receive a confirmation email with the date and time of the interview once the tutor accepts
              your request.
              <br /> ⚠️ Tutor is in {tutorTimeZone} timezone
            </Alert>
            <Field name="start_at_slots" validate={notEmptyArray}>
              {({ input }) => (
                <InterviewCalendar
                  value={input.value || []}
                  editMode={editMode}
                  showBetweenMinMaxInterval={isParent}
                  isLoading={isLoadingAvailability}
                  start={calendarPeriod.start}
                  end={calendarPeriod.end}
                  availableIntervals={availableIntervals}
                  onChange={input.onChange}
                />
              )}
            </Field>

            <Row>
              <Col lg={15}>
                <h4 className="mb-3 mt-5">Additional notes</h4>
                <p className="pb-2">
                  Anything in particular you’re concerned about? Do you have specific goals in mind?
                  <br /> Make sure to let the tutor know so you can make the most of the meeting!
                </p>

                <Field name="additional_notes">
                  {({ input }) => <FormControl {...input} disabled={!editMode} as="textarea" rows={5} />}
                </Field>

                {!isEmpty(values.start_at_slots) && (
                  <>
                    <p className="mt-5 mb-2">
                      To confirm, these are the days and times for a video interview that the tutor will choose from:
                    </p>
                    {values.start_at_slots.map((start, i) => (
                      <InterviewSlot key={i} start={start} />
                    ))}
                  </>
                )}
              </Col>
            </Row>
          </div>
          {editMode && (
            <>
              <hr className="m-0" />
              <div className="p-4">
                <Button
                  variant="outline-primary"
                  className="anl-btn-interviewreq"
                  disabled={invalid || submitting}
                  onClick={handleSubmit}
                >
                  {values.id ? "Edit interview request" : "Request interview"}
                </Button>
                <Button variant="link" className="btn-link-primary ml-4" onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Form>
  )
}

export default InterviewForm
