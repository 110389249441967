import { apiClient } from "@/api"
import { showErrors } from "@/common/alerter"
import { useCallback, useEffect, useState } from "react"

export const useGetLessonspaceClassroom = ({ scheduleId }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const fetchLessonspaceSession = useCallback(async () => {
    if (!scheduleId) {
      setError("Invalid or missing schedule ID")
      return
    }
    try {
      setLoading(true)
      setError(null)
      const { data } = await apiClient.get(`/api/v2/lessonspaces/classrooms/${scheduleId}`)
      setData(data || "")
    } catch (e) {
      setError(e.message)
      showErrors(e)
    } finally {
      setLoading(false)
    }
  }, [scheduleId])

  useEffect(() => {
    if (scheduleId) {
      fetchLessonspaceSession()
    }
  }, [fetchLessonspaceSession, scheduleId])

  return { loading, data, error }
}

export default useGetLessonspaceClassroom
