import { apiClient } from "@/api"
import LoadingSpinner from "@/common/loading"
import CreateIcon from "@mui/icons-material/Create"
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import ActivateOrDeactivateUser from "../actions/deactivate_user"

export const SchoolAdminManagePage = ({ newSchoolAdminPath, currentUser }) => {
  const [state, setState] = useState({
    schoolAdmins: [],
    totalCount: 0,
    perPage: 5,
    page: 0,
    loading: true,
    loadingSchoolAdminPage: true,
    density: "compact",
    updateStatusLoading: false,
  })
  const { schoolAdmins, totalCount, perPage, page } = state
  const [filter, setFilter] = useState({
    name: "",
    email: "",
  })

  const rows = useMemo(() => {
    return schoolAdmins.map((schoolAdmin, index) => {
      return {
        id: index,
        identifier: schoolAdmin.id,
        active: schoolAdmin.active,
        school_admin_setting_id: schoolAdmin.school_admin_setting_id,
        name: `${schoolAdmin.first_name} ${schoolAdmin.last_name}`,
        email: schoolAdmin.email,
      }
    })
  }, [schoolAdmins])

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "table-header",
      resizable: false,
      flex: 1,
      filterable: true,
      renderCell: params => <a href={`/school_admin/school_admins/${params.row.identifier}`}>{params.row.name}</a>,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "table-header",
      resizable: false,
      flex: 1,
      filterable: true,
      loading: false,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: params => [
        <ActivateOrDeactivateUser
          key={params.row.identifier}
          currentUser={currentUser}
          params={params}
          state={state}
          setState={setState}
        />,
        <GridActionsCellItem
          key="edit"
          icon={<CreateIcon />}
          label="edit"
          className="textPrimary"
          onClick={() => (window.location.href = `/school_admin/school_admins/${params.row.identifier}/edit`)}
          color="primary"
        />,
      ],
    },
  ]

  useEffect(() => {
    const listSchoolAdmins = async () => {
      const endpoint = `/api/v2/school_admins?paginate=true&page=${page}&per_per_page=${perPage}&name=${
        filter.name || ""
      }&email=${filter.email || ""}`

      try {
        const response = await apiClient.get(endpoint)
        setState({
          page,
          perPage,
          schoolAdmins: response && response.data && response.data.school_admins,
          totalCount: response && response.data && response.data.total_count,
          loading: false,
        })
      } catch (error) {
        setState({
          page,
          perPage,
          schoolAdmins: [],
          totalCount: 0,
          loading: false,
          density: "compact",
          updateStatusLoading: false,
        })
      }
    }
    listSchoolAdmins()
  }, [filter.email, filter.name, page, perPage])

  const onFilterChange = useCallback(filterModel => {
    const { field, value } = filterModel.items[0]
    if (field) {
      setState(prevState => ({ ...prevState, loading: true }))
      setFilter({
        [field]: encodeURIComponent(value) || "",
      })
    }
  }, [])

  const onDensityChange = density => {
    setState(prevState => ({
      ...prevState,
      density,
    }))
  }

  return (
    <div>
      <Container className="school-admins-dashboard-content">
        <div className="d-flex justify-content-between align-items-center">
          <h2>School Admins</h2>
          <Button href={newSchoolAdminPath} variant="outline-primary">
            New School Admin
          </Button>
        </div>
        <Row>
          <Col as="main" xs={24}>
            <Card className="px-3 py-4">
              <div style={{ height: "100%", width: "100%" }}>
                {state.loadingSchoolAdminPage ? (
                  <LoadingSpinner />
                ) : (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    autosizeOptions={{
                      includeHeaders: true,
                      includeOutliers: true,
                      outliersFactor: 1.5,
                      expand: true,
                    }}
                    onFilterModelChange={onFilterChange}
                    paginationModel={{ pageSize: perPage, page: page }}
                    onPaginationModelChange={({ page, pageSize }) => {
                      setState(prevState => ({ ...prevState, page, perPage: pageSize, loading: true }))
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    pagination
                    paginationMode="server"
                    rowCount={totalCount}
                    autoHeight
                    className="bg-white"
                    density={state.density}
                    onDensityChange={onDensityChange}
                    loading={state.loading}
                    sx={{
                      border: "none",
                      fontFamily: "'Poppins', sans-serif",

                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#f5f5f5",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "600",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        padding: 0,
                        margin: 0,
                      },
                      "& .MuiButton-root": {
                        color: "#3c00f5",
                      },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      pagination: {
                        component: "div",
                      },
                      tablePagination: {
                        component: "div",
                      },
                    }}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
