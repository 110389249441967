import logger from "@/helpers/logger"
import { handleRedirect } from "../utils/apiUtils"

/**
 * Logs details of an HTTP request.
 * @param {Object} request - The request object to be logged.
 * @param {string} request.method - The HTTP method of the request.
 * @param {string} request.url - The URL of the request.
 * @param {Object} request.body - The body of the request.
 * @param {Object} request.headers - The headers of the request.
 * @returns {Object} The original request object.
 */
const logRequest = request => {
  logger.info("Request:", request.method.toUpperCase(), request.url)
  logger.info("Request Body:", request.body)
  logger.info("Request Headers:", request.headers)
  return request
}

/**
 * Logs details of an HTTP response.
 * @param {Object} response - The response object to be logged.
 * @param {number} response.status - The status code of the response.
 * @param {Object} response.config - Configuration of the response.
 * @param {string} response.config.url - The URL of the response.
 * @param {Object} response.data - The data of the response.
 * @returns {Object} The original response object.
 */
const logResponse = response => {
  logger.info("Response:", response.status, response.config.url)
  logger.info("Response Data:", response.data)
  return response
}

/**
 * Logs details of an error that occurred during an HTTP request or response.
 * @param {Object} error - The error object to be logged.
 * @param {Object} [error.response] - The response object if the error occurred at the response level.
 * @param {Object} [error.request] - The request object if the error occurred at the request level.
 * @param {string} [error.message] - The error message if the error is neither response nor request related.
 * @returns {Promise} A rejected promise with the original error.
 */
const logError = error => {
  if (error.response) {
    logger.error("Response Error:", error.response.status, error.response.config.url)
    logger.error("Response Error Data:", error.response.data)
  } else if (error.request) {
    logger.error("Request Error:", error.request)
  } else {
    logger.error("Error:", error.message)
  }
  return Promise.reject(error)
}

/**
 * Sets up request and response interceptors for an Axios client instance.
 * @param {Object} client - The Axios client instance to set up interceptors for.
 * @returns {void}
 */
export const setupInterceptors = client => {
  // Request interceptor
  client.interceptors.request.use(logRequest, error => Promise.reject(error))

  // Response interceptor
  client.interceptors.response.use(
    response => {
      handleRedirect(response?.headers)
      return logResponse(response)
    },
    error => {
      handleRedirect(error.response?.headers)
      return logError(error)
    }
  )
}
