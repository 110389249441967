import { postAndRedirect } from "@/api"
import { setFieldData } from "@/helpers/forms"
import React from "react"
import { Form } from "react-final-form"
import FormFields from "./FormFields"

const TEMPORARY_BRAINTRUST_ID = "braintrust"
export const isBraintrustOrganization = id => id === TEMPORARY_BRAINTRUST_ID

const AssignTutorToStudent = ({ dataSources, urls }) => {
  const { student, organizations, partnerships, hasCC } = dataSources

  const onSubmit = async values => {
    const isBraintrust = isBraintrustOrganization(values.organization_id)
    const payload = {
      student_id: student.id,
      tutor_to_student: {
        student_id: student.id,
        tutor_id: parseInt(values.tutor_id),
        tutor_pay:
          isBraintrust && values.tutor_pay_kind === "percent"
            ? parseFloat(values.session_rate)
            : parseFloat(values.tutor_pay),
        tutor_pay_kind: isBraintrust ? values.tutor_pay_kind : "percent",
        tutor_payout_kind: values.tutor_payout_kind || "idle",
        parent_charge_kind: values.parent_charge_kind || "idle",
        session_rate: parseFloat(values.session_rate),
        organization_id: isBraintrust ? null : values.organization_id,
        partnership_id: values.partnership_id,
      },
    }

    postAndRedirect(urls.save, urls.redirect, payload)
  }

  return (
    <Form mutators={{ setFieldData }} onSubmit={onSubmit}>
      {({ submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormFields
            student={student}
            organizations={organizations}
            partnerships={partnerships}
            urls={urls}
            hasCC={hasCC}
            submitting={submitting}
          />
        </form>
      )}
    </Form>
  )
}

export default AssignTutorToStudent
