import pluralize from "pluralize"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"

import Message from "./Message"
import MessageButton from "./MessageButton"

import { apiClient } from "@/api"
import IndeterminateProgressBar from "../blocks/IndeterminateProgressBar"
import { getQueryStringFromParams } from "../find_tutor/serializeParams"
import { getChatStatus } from "./Chat"

const Messages = ({ chat, showBothParties, onClose, dataSources }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [buttonText, setButtonText] = useState("Message")
  const [changeSubject, setChangeSubject] = useState(dataSources.changeSubject)
  const [subject, setSubject] = useState(dataSources.subject)

  useEffect(() => {
    findMessages(dataSources.urls.find_thread, { chat_id: chat.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat])

  const findMessages = async (url, params) => {
    setIsLoading(true)
    const queryString = getQueryStringFromParams(params)
    try {
      const {
        data: { thread },
      } = await apiClient.get(url + queryString)
      setMessages(thread)

      const is_first = thread.length === 0 || thread[0].sender_role === dataSources.from.role

      setButtonText(is_first ? "Message" : "Reply")
      setChangeSubject(is_first)
      setSubject(is_first ? "" : thread[0].subject)
    } catch (_) {
      setMessages([])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <IndeterminateProgressBar className="messages-progress-bar" />}
      {!isLoading && <div className="chat-progress-bar-spacer" />}
      <Container className={"py-2 px-0 panel-wrapper" + (isLoading ? " hidden" : "")}>
        <Container className="panel_header no-bottom-border-mobile mb-1">
          <Row className="p-2">
            <Col xs={{ span: 21 }} lg={{ span: 16 }}>
              <div className="text-primary font-weight-bold recipient-name">
                {showBothParties === true ? chat.recipient_name + " & " + chat.sender_name : chat.recipient_name}
              </div>
              {chat.last_message_sent && (
                <div className="text-gray-chat">Last message sent {chat.last_message_sent} ago</div>
              )}
            </Col>
            <Col xs={{ span: 3 }} className="text-right chat-close-button-container hidden-lg">
              <a href="#" onClick={onClose} className="chat-close-button-link">
                <span className="text-gray-chat">&times;</span>
              </a>
            </Col>
            {showBothParties && (
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <div className="d-flex flex-lg-column align-items-lg-end text-gray-chat">
                  <div>
                    <span className="pr-3">{pluralize("Interview", chat.count_interviews, true)}</span>
                    <span>{pluralize("Session", chat.count_sessions, true)}</span>
                  </div>
                  <div className="pl-3">{getChatStatus(chat, true)}</div>
                </div>
              </Col>
            )}
            {!showBothParties && (
              <Col xs={{ span: 24 }} lg={{ span: 8 }} className="text-center text-lg-right message-button-mobile">
                <MessageButton
                  buttonText={buttonText}
                  dataSources={{
                    urls: dataSources.urls,
                    from: dataSources.from,
                    recipient: {
                      id: chat.recipient_id,
                      name: chat.recipient_name,
                      averageResponseTime: chat.recipient_average_response_time_in_words,
                    },
                    subject: subject,
                    changeSubject: changeSubject,
                  }}
                />
              </Col>
            )}
          </Row>
        </Container>
        {!isLoading && (
          <Container className="pb-1 card bg-lg-card-no-shadow">
            {messages.map((message, idx) => (
              <Message key={`${message.id}_${idx}`} message={message} />
            ))}
          </Container>
        )}
      </Container>
    </>
  )
}

export default Messages
