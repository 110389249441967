import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { useCallback, useEffect, useState } from "react"

const getFormattedDate = date => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

const formatISOToTimeString = isoString => {
  const date = new Date(isoString)
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12
  const minutesStr = minutes < 10 ? "0" + minutes : minutes

  return `${hours}:${minutesStr} ${ampm}`
}

export const useFetchSessions = (date = new Date()) => {
  if (!(date instanceof Date)) {
    Alerter.error("Invalid date argument")
  }
  const [sessions, setSessions] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchSessions = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const formattedDate = getFormattedDate(date)
      const response = await apiClient.get(`/api/v2/school_admins/sessions/${formattedDate}`)
      if (!response || !response.data) {
        setSessions([])
      } else {
        const formatResponseTime = response.data.sessions.map(session => ({
          ...session,
          time: formatISOToTimeString(session.time),
        }))
        setSessions(formatResponseTime)
      }
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [date])

  useEffect(() => {
    fetchSessions()
  }, [fetchSessions])

  return { sessions, loading, error }
}

export default useFetchSessions
