import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { TIME_SUGGESTED_INTERVIEW } from "@/constants"
import { notEmptyArray, required } from "@/helpers/validators"
import { isEmpty, keys, map, reduce } from "lodash/fp"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Col, FormControl, Modal, Row } from "react-bootstrap"
import { Field, Form } from "react-final-form"
import { ReactComponent as ChevronNext } from "../../../../assets/images/icons/chevron-next.svg"
import { ReactComponent as ChevronPrev } from "../../../../assets/images/icons/chevron-prev.svg"
import { formatDate, formatDateTimeKeepZone, getEndOfWeek, getStartOfWeek } from "../../../helpers/dates"
import InterviewCalendar from "../schedule_interview/InterviewCalendar"
import InterviewSlot from "../schedule_interview/InterviewSlot"

const getPeriodMonth = ({ start, end }) => {
  const momentStart = moment(start)
  const momentEnd = moment(end)
  if (momentStart.isSame(momentEnd, "month")) {
    return momentStart.format("MMMM")
  }
  return `${momentStart.format("MMMM")}/${momentEnd.format("MMMM")}`
}

const AlternateTimeModalContent = ({ interview, onSubmit, onClose }) => {
  const [availableIntervals, setAvailableIntervals] = useState([])
  const [isLoadingAvailability, setIsLoadingAvailability] = useState(false)
  const [calendarPeriod, setCalendarPeriod] = useState({
    start: getStartOfWeek(new Date()),
    end: getEndOfWeek(new Date()),
  })

  const initialValues = useMemo(() => {
    return {
      start_at_slots:
        interview.status === TIME_SUGGESTED_INTERVIEW
          ? interview.start_at_slots.map(v => formatDateTimeKeepZone(v))
          : [],
      call_details: interview.call_details,
    }
  }, [interview])

  useEffect(() => {
    getAvailability(calendarPeriod)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarPeriod])

  const getAvailability = async ({ start: since, end: till }) => {
    setIsLoadingAvailability(true)
    try {
      const { data } = await apiClient.get(`/api/tutor/availability/${interview.tutor.id}/free`, {
        params: { since, till },
      })
      const getDayIntervals = date => map(value => ({ date, ...value }))(data[date])
      const arrayData = reduce((result, date) => [...result, ...getDayIntervals(date)], [])(keys(data))
      setAvailableIntervals(arrayData)
    } catch (_) {
      Alerter.error("An error occurred while loading data")
    } finally {
      setIsLoadingAvailability(false)
    }
  }

  const prev = () => {
    setCalendarPeriod(({ start, end }) => ({
      start: formatDate(moment(start).subtract(1, "w")),
      end: formatDate(moment(end).subtract(1, "w")),
    }))
  }

  const next = () => {
    setCalendarPeriod(({ start, end }) => ({
      start: formatDate(moment(start).add(1, "w")),
      end: formatDate(moment(end).add(1, "w")),
    }))
  }

  const handleSubmit = values => {
    onSubmit({
      start_at_slots: values.start_at_slots.map(v => formatDateTimeKeepZone(v)),
      call_details: values.call_details,
    })
  }

  return (
    <>
      <Modal.Header className="px-4 pt-3 pb-2 borderless">
        <Modal.Title>Suggest another time to connect.</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ values, invalid, submitting, handleSubmit }) => (
            <>
              <Row>
                <Col lg={10}>
                  <p>
                    Please choose some time slots on the calendar. It’s good to provide several options. If you need to
                    edit your selection, just click again on the same time to remove it from the calendar.
                  </p>
                </Col>
              </Row>

              <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="m-0">{getPeriodMonth(calendarPeriod)}</h4>
                <div className="d-flex align-items-center">
                  <Button variant="outline-primary" className="btn-circle" onClick={prev}>
                    <ChevronPrev />
                  </Button>
                  <Button variant="outline-primary" className="btn-circle ml-3" onClick={next}>
                    <ChevronNext />
                  </Button>
                </div>
              </div>

              <Field name="start_at_slots" validate={notEmptyArray}>
                {({ input }) => (
                  <InterviewCalendar
                    value={input.value || []}
                    editMode={true}
                    showBetweenMinMaxInterval={true}
                    isLoading={isLoadingAvailability}
                    start={calendarPeriod.start}
                    end={calendarPeriod.end}
                    availableIntervals={availableIntervals}
                    onChange={input.onChange}
                  />
                )}
              </Field>

              <Row>
                <Col lg={10}>
                  <h4 className="mt-5 mb-3">Add call details</h4>
                  <p className="pb-2">
                    How should the parent contact you? Paste a link for a Zoom or Google Meet! This can be edited later.
                  </p>
                  <Field name="call_details" validate={required}>
                    {({ input }) => <FormControl {...input} as="textarea" rows={5} />}
                  </Field>
                </Col>
              </Row>

              <Row>
                <Col>
                  {!isEmpty(values.start_at_slots) && (
                    <>
                      <p className="mt-5 mb-2">
                        To confirm, these are the days and times for a video interview that the parent will choose from:
                      </p>
                      {values.start_at_slots.map((start, i) => (
                        <InterviewSlot key={i} start={start} />
                      ))}
                    </>
                  )}
                </Col>
              </Row>

              <div className="mt-5">
                <Button
                  variant="outline-primary"
                  className="anl-btn"
                  disabled={invalid || submitting}
                  onClick={handleSubmit}
                >
                  Send proposed time to parent
                </Button>
                <Button variant="link" className="link-primary ml-5" onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </>
  )
}

export default AlternateTimeModalContent
