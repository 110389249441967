import { postAndRedirect } from "@/api"
import arrayMutators from "final-form-arrays"
import React from "react"
import Button from "react-bootstrap/Button"
import { Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import {
  RejectionConfirmationDialog,
  useRejectionDialog,
} from "../rejection_confirmation_dialog/RejectionConfirmationDialog"
import Degree from "./Degree"

const onApprove = (values, urls) => {
  postAndRedirect(urls.approve, urls.redirect, {
    entity: { type: "TutorDegree", ids: values.degree_ids },
  })
}

const buttonDisabled = form => {
  return !form.getFieldState("degree_ids") || form.getFieldState("degree_ids").length === 0
}

const DegreesToReview = ({ degreesToShow, urls }) => {
  const { show, dialogProps } = useRejectionDialog()

  if (degreesToShow.length === 0) {
    return null
  }

  return (
    <div className="col-lg-18 pending-area">
      <h5>Degrees</h5>

      <Form initialValues={{ degree_ids: [] }} onSubmit={() => {}} mutators={{ ...arrayMutators }}>
        {({ form }) => (
          <>
            <FieldArray name="degree_ids">
              {({ fields }) => (
                <>
                  {degreesToShow.map(degree => (
                    <Degree key={degree.id} degree={degree} fields={fields} />
                  ))}
                </>
              )}
            </FieldArray>
            <hr className="thin" />
            <Button
              disabled={buttonDisabled(form)}
              variant="outline-primary"
              onClick={() => onApprove(form.getState().values, urls)}
              data-test="degree-approval"
            >
              Approve selected
            </Button>
            <Button
              disabled={buttonDisabled(form)}
              variant="secondary"
              className="reject"
              onClick={() => {
                show(form.getState().values.degree_ids, urls, "TutorDegree", "degree")
              }}
            >
              Reject selected
            </Button>
          </>
        )}
      </Form>

      <RejectionConfirmationDialog {...dialogProps} />
    </div>
  )
}

export default DegreesToReview
