import React from "react"
import { checkUrl, getScheduleOfflineAddress } from "../../helpers"
import { MeetingFormats } from "../../../../constants"

export const Location = ({ schedule, role }) => {
  let url

  switch (schedule.meeting_format) {
    case MeetingFormats.Offline:
      return <span>{getScheduleOfflineAddress(schedule)}</span>

    case MeetingFormats.Online: {
      url = schedule.meeting_info ? checkUrl(schedule.meeting_info) : ""
      if (url) {
        return (
          <>
            <span className="mr-1">Online</span>
            <a href={schedule.meeting_info} target="_blank" rel="noreferrer">
              {schedule.meeting_info}
            </a>
          </>
        )
      }
      return <span>Online</span>
    }

    case MeetingFormats.LessonSpace: {
      url = schedule.meeting_info ? checkUrl(schedule.meeting_info) : ""
      
      if(schedule?.id) {
        return (
          <a href={`/${role}/dashboard/classroom/${schedule?.id}`} target="_blank" rel="noreferrer">
            Lessonspace
          </a>
        )
      } 
      if (url) {
        return (
          <>
            <span className="mr-1">Lessonspace</span>
            <a href={schedule.meeting_info} target="_blank" rel="noreferrer">
              {schedule.meeting_info}
            </a>
          </>
        )
      }
      
      return <span>Lessonspace</span>
    }

    default:
      return <span>—</span>
  }
}
