import { postAndRedirect } from "@/api"
import arrayMutators from "final-form-arrays"
import React from "react"
import Button from "react-bootstrap/Button"
import { Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import {
  RejectionConfirmationDialog,
  useRejectionDialog,
} from "../rejection_confirmation_dialog/RejectionConfirmationDialog"
import Certificate from "./Certificate"

const onApprove = (values, urls) => {
  postAndRedirect(urls.approve, urls.redirect, {
    entity: { type: "TutorCertificate", ids: values.certificate_ids },
  })
}

const buttonDisabled = form => {
  return !form.getFieldState("certificate_ids") || form.getFieldState("certificate_ids").length === 0
}

const CertificatesToReview = ({ certificatesToShow, urls }) => {
  const { show, dialogProps } = useRejectionDialog()

  if (certificatesToShow.length === 0) {
    return null
  }

  return (
    <div className="col-lg-18 pending-area">
      <h5>Training and certificates</h5>

      <Form initialValues={{ certificate_ids: [] }} onSubmit={() => {}} mutators={{ ...arrayMutators }}>
        {({ form }) => (
          <>
            <FieldArray name="certificate_ids">
              {({ fields }) => (
                <>
                  {certificatesToShow.map(certificate => (
                    <Certificate key={certificate.id} certificate={certificate} fields={fields} />
                  ))}
                </>
              )}
            </FieldArray>
            <hr className="thin" />
            <Button
              disabled={buttonDisabled(form)}
              variant="outline-primary"
              onClick={() => onApprove(form.getState().values, urls)}
            >
              Approve selected
            </Button>
            <Button
              disabled={buttonDisabled(form)}
              variant="secondary"
              className="reject"
              onClick={() => {
                show(form.getState().values.certificate_ids, urls, "TutorCertificate", "certificate")
              }}
            >
              Reject selected
            </Button>
          </>
        )}
      </Form>

      <RejectionConfirmationDialog {...dialogProps} />
    </div>
  )
}

export default CertificatesToReview
