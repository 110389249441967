import { post } from "@/api"
import Alerter from "@/common/alerter"
import { useCallback, useState } from "react"

export const useCSVUpload = ({ openingId, onLoad, onError }) => {
  const [isLoading, setIsLoading] = useState(false)

  const request = useCallback(
    async ({ school_id, file }) => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("school_id", parseInt(school_id, 10))
      if (openingId) formData.append("opening_id", parseInt(openingId, 10))
      try {
        const result = await post("students/import_csv", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        onLoad(result.data)
        Alerter.success("File successfully uploaded")
      } catch (e) {
        if (onError && e?.response?.data) onError(e.response.data)
        Alerter.error(e.message)
      } finally {
        setIsLoading(false)
      }
    },
    [openingId, onLoad, onError]
  )

  return [isLoading, request]
}
