import cn from "classnames"
import React from "react"
import { Dropdown } from "react-bootstrap"

import { apiClient } from "@/api"
import CouponInput from "./CouponInput"

const CouponDropdown = ({ active, info, className }) => {
  const onValidationSucceded = async ({ code }) => {
    await apiClient.post("/api/coupons", { code })
    document.location.reload()
  }

  return (
    <Dropdown className={cn("coupon-dropdown", className)}>
      <Dropdown.Toggle
        as="div"
        className={cn("coupon-dropdown_toggle", "dropdown-toggle-no-icon", "discount-icon", !active && "-inactive")}
      ></Dropdown.Toggle>

      <Dropdown.Menu className="coupon-dropdown_menu p-3">
        <p>{active ? "Discount code activated" : "Add a discount code"}</p>
        {active && (
          <div className="coupon-dropdown_info">
            <div className="coupon-dropdown_circle discount-icon"></div>
            <div className="coupon-dropdown_text">{info.additional_info}</div>
          </div>
        )}
        <CouponInput placeholder="Add code" onValidationSucceded={onValidationSucceded} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CouponDropdown
