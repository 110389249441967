import { Checkbox, FormControlLabel, Skeleton, Stack } from "@mui/material"
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Form as FForm, Field } from "react-final-form"
import SelectWithSearchInput from "../../../common/LookupWithSearchInput"
import DurationField from "../../../components/blocks/DurationField"
import FormRow from "../../../components/blocks/FormRow"
import { Entities, MeetingFormats } from "../../../constants"
import { DataContext, QueryContext } from "../../../contexts"
import { formatDateTimeKeepZone, minutesToHours } from "../../../helpers/dates"
import useViewport from "../../../hooks/useViewport"
import TimePicker from "../../../library/timePicker"
import Condition from "../../Condition"
import { PropsInjector } from "../../PropsInjector"
import { createSessionFromReport } from "../configurations/domain"
import useFetchScheduleProgram from "../../opening_module/pages/curriculum/hooks/useFetchScheduleProgram"
import { AssignmentTypes } from "../constants"
import ButtonSkeletonLoader from "../../../common/skeleton_loaders/ButtonSkeletonLoader"
import LookupFieldLoader from "./session_details/LookupFieldLoader"

const colProps = { sm: 17, md: 18, className: "mb-0 form-input-align flex-column" }
const labelProps = { sm: 7, md: 6, className: "v-2 form-label-align " }

const FieldNames = Object.freeze({
  Time: "started_at",
  Duration: "duration",
  Location: "meeting_format",
  LocationComment: "meeting_info",
  Level: "level",
  Lesson: "lesson",
  AssessmentOnly: "assessment_only",
  PreAssessment: "pre_assessment",
})

const SyntheticFieldNames = Object.freeze({
  Address: "address",
  Link: "link",
})

const formatTypes = [
  { value: MeetingFormats.Online, label: "Online" },
  { value: MeetingFormats.Offline, label: "Offline" },
  { value: MeetingFormats.LessonSpace, label: "Lessonspace" },
]

const defaultValues = {
  [FieldNames.Time]: "",
  [FieldNames.Duration]: "",
  [FieldNames.Location]: "",
  [FieldNames.LocationComment]: "",
}

const validate =
  ({ maxBillableMin, disableLocation }) =>
  formState => {
    const acc = {}
    const time = formState[FieldNames.Time]
    const duration = formState[FieldNames.Duration]
    const location = formState[FieldNames.Location]
    const address = formState[SyntheticFieldNames.Address]
    const link = formState[SyntheticFieldNames.Link]
    if (!time) acc[FieldNames.Time] = "Required field"
    if (typeof duration !== "number" && !duration) acc[FieldNames.Duration] = "Required field"
    else if (duration <= 0) acc[FieldNames.Duration] = "Duration should be greater than 0"
    else if (maxBillableMin > 0 && duration > maxBillableMin) {
      const [billableHours, billableMinutes] = minutesToHours(maxBillableMin)
      acc[FieldNames.Duration] = `Sessions can be no longer than ${billableHours > 0 ? `${billableHours}h ` : ""}
    ${billableMinutes > 0 ? `${billableMinutes}min` : ""}`
    }
    if (!disableLocation) {
      if (!location) acc[FieldNames.Location] = "Required field"
      if (location === MeetingFormats.Online && !link) acc[SyntheticFieldNames.Link] = "Required field"
      if (location === MeetingFormats.Offline && !address) acc[SyntheticFieldNames.Address] = "Required field"
    }
    return acc
  }

const buildFormResult = (formState, initialValue = {}) => {
  const { [SyntheticFieldNames.Address]: address, [SyntheticFieldNames.Link]: link, ...restState } = formState

  const { [formState[FieldNames.Location]]: comment } = {
    [MeetingFormats.Online]: link,
    [MeetingFormats.Offline]: address,
  }

  return {
    ...initialValue,
    ...restState,
    [FieldNames.Time]: formatDateTimeKeepZone(formState[FieldNames.Time]),
    [FieldNames.LocationComment]: comment,
  }
}

const SessionDetailsForm = props => {
  const {
    initialValues,
    onSubmit,
    session,
    loading,
    disableLocation,
    maxBillableMin,
    pencilSpaceLink,
    date,
    touched,
    children,
  } = props

  const { isMobileViewport } = useViewport()
  const { schedule, currentUser } = useContext(DataContext)
  const [lesson, setLesson] = useState(initialValues[FieldNames.Lesson] || "")
  const [level, setLevel] = useState(initialValues[FieldNames.Level] || "")
  const [preAssessment, setPreAssessment] = useState(initialValues[FieldNames.Assessment] || "")
  const [assessmentOnly, setAssessmentOnly] = useState(initialValues[FieldNames.AssessmentOnly] || "")
  const [billableHours, billableMinutes] = minutesToHours(maxBillableMin)
  const {
    programLevels,
    preAssessments,
    isCurriculumProgram,
    loading: loadingProgram,
  } = useFetchScheduleProgram({ scheduleId: schedule?.id })
  const [lessonsListByLevel, setLessonListByLevel] = useState([])
  const { feature_flags } = currentUser

  const programLevelsList = (programLevels || [])?.map(programLevel => ({
    title: programLevel.name,
    value: programLevel.id,
  }))

  const preAssessmentsList = (preAssessments || []).map(preAssessment => ({
    title: `${preAssessment.name}`,
    value: preAssessment.id,
  }))

  useEffect(() => {
    const levelId = session?.assignment?.program_level_id
    const assignmentType = session?.assignment_type
    const assignmentId = session?.assignment?.id
    const isLessonAssessment = levelId && programLevels?.length && session?.assignment_type === AssignmentTypes.LESSON
    const isPreAssessment = assignmentId && assignmentType === AssignmentTypes.PROGRAM_ASSESSMENT

    if (isLessonAssessment) {
      const programLevel = programLevels?.find(prLevel => prLevel.id === levelId)
      const lessonsList = programLevel?.lessons.map(lesson => ({
        title: lesson.name,
        value: lesson.id,
      }))
      setLevel(levelId)
      setLesson(assignmentId)
      setLessonListByLevel(lessonsList)
    }

    if (isPreAssessment) {
      setAssessmentOnly(true)
      setPreAssessment(assignmentId)
    }
  }, [programLevels, session, session?.assignment, session?.assignment_type])

  const submit = async data => {

    if (lesson && level) {
      data["assignment_type"] = "Lesson"
      data["assignment_id"] = lesson
    }

    if (assessmentOnly && preAssessment) {
      data["assignment_type"] = "ProgramAssessment"
      data["assignment_id"] = preAssessment
    }

    onSubmit(data)
  }

  return (
    <FForm
      initialValues={{ ...defaultValues, ...initialValues }}
      validate={validate({ maxBillableMin, disableLocation })}
      onSubmit={submit}
    >
      {({ values, errors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field type="time" name={FieldNames.Time}>
            {({ input, meta }) => (
              <FormRow
                label="Time"
                RowProps={{ className: "d-flex mb-0 flex-column" }}
                ColProps={colProps}
                LabelProps={labelProps}
                className="mb-4 d-flex flex-column"
              >
                <TimePicker
                  {...input}
                  invalid={(touched || meta.touched) && meta.error}
                  date={date}
                  className="v-2 w-67"
                  placeholder="Set time"
                >
                  <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                </TimePicker>
              </FormRow>
            )}
          </Field>

          <Field type="text" name={FieldNames.Duration}>
            {({ input, meta }) => (
              <FormRow
                label="Duration"
                RowProps={{ className: "d-flex mb-0 flex-column" }}
                ColProps={colProps}
                LabelProps={labelProps}
                className="mb-2 d-flex flex-column"
              >
                <DurationField {...input} placeholder="1:30" wrapperClassName="w-67" className="v-2" />
                <Form.Control isInvalid={(touched || meta.touched) && meta.error} hidden readOnly />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                {(billableHours > 0 || billableMinutes > 0) && !meta.error?.startsWith("Sessions can be no longer") && (
                  <span className="form-control_caption">
                    Sessions can be no longer than {billableHours > 0 ? `${billableHours}h ` : ""}
                    {billableMinutes > 0 ? `${billableMinutes}min` : ""}
                  </span>
                )}
              </FormRow>
            )}
          </Field>

          <FormRow
            label="Location"
            RowProps={{ className: "d-flex mb-0 flex-column" }}
            ColProps={{ ...colProps, className: "d-flex mb-0 d-flex flex-column" }}
            LabelProps={labelProps}
          >
            <Col>
              <Row
                xs={24}
                className={`mb-2 form-input-align mb-0 ${isMobileViewport ? "flex-column" : "align-items-center"}`}
              >
                <Field type="radio" name={FieldNames.Location}>
                  {({ input }) =>
                    formatTypes
                      .map(({ value, label }) => (
                        <Form.Check
                          name={FieldNames.Location}
                          type="radio"
                          className={`v-2 ${isMobileViewport ? "mb-2" : "mr-3"}`}
                          id={`${FieldNames.Location}-${value}`}
                          key={value}
                          label={label}
                          custom
                          {...input}
                          value={value}
                          checked={values[FieldNames.Location] === value}
                          disabled={Boolean(disableLocation)}
                        />
                      ))
                  }
                </Field>
              </Row>
              <Row xs={24} className="mt-2">
              <>
                    <Condition when={FieldNames.Location} is={MeetingFormats.Online}>
                      <Field type="text" name={SyntheticFieldNames.Link}>
                        {({ input, meta }) => (
                          <Form.Control
                            className="v-2"
                            placeholder="Link"
                            isInvalid={(touched || meta.touched) && meta.error}
                            disabled={Boolean(disableLocation)}
                            {...input}
                          />
                        )}
                      </Field>
                    </Condition>
                    <Condition when={FieldNames.Location} is={MeetingFormats.Offline}>
                      <Field type="text" name={SyntheticFieldNames.Address}>
                        {({ input, meta }) => (
                          <Form.Control
                            className="v-2"
                            placeholder="Address"
                            isInvalid={(touched || meta.touched) && meta.error}
                            disabled={Boolean(disableLocation)}
                            {...input}
                          />
                        )}
                      </Field>
                    </Condition>
                  </>
              </Row>
            </Col>
          </FormRow>

          {feature_flags["curriculum_enabled"] && (
            <Fragment>
              {loadingProgram ? (
                <LookupFieldLoader />
              ) : (
                isCurriculumProgram && (
                  <Field type="text" name={FieldNames.Level}>
                    {() => (
                      <FormRow
                        label="Level"
                        RowProps={{ className: "d-flex mb-0 flex-column" }}
                        ColProps={{ ...colProps, className: "d-flex mb-0 flex-column" }}
                        LabelProps={labelProps}
                        className="mb-2"
                      >
                        <SelectWithSearchInput
                          id="level"
                          defaultValue={""}
                          value={level}
                          disabled={Boolean(assessmentOnly)}
                          handleOnChangeSelect={event => {
                            const levelId = event.target.value
                            const programLevel = programLevels?.find(prLevel => prLevel.id === levelId)
                            const lessonsList = programLevel?.lessons.map(lesson => ({
                              title: lesson.name,
                              value: lesson.id,
                            }))

                            setLevel(levelId)
                            setLessonListByLevel(lessonsList)
                          }}
                          style={{
                            borderRadius: "10px",
                            maxWidth: "100%",
                          }}
                          inputStyle={{
                            minHeight: "0px !important",
                          }}
                          menuListStyle={{
                            overflowX: "scroll",
                            maxHeight: "300px",
                          }}
                          name={FieldNames.Level}
                          optionsList={programLevelsList}
                          placeholder="Select Level"
                          showSearchBar={false}
                          searchText={""}
                        />
                      </FormRow>
                    )}
                  </Field>
                )
              )}

              {loadingProgram ? (
                <LookupFieldLoader />
              ) : (
                isCurriculumProgram && (
                  <Field type="text" name={FieldNames.Lesson}>
                    {() => (
                      <FormRow
                        label="Lesson"
                        RowProps={{ className: "d-flex mb-0 flex-column" }}
                        ColProps={{ ...colProps, className: "d-flex mb-0 d-flex flex-column" }}
                        LabelProps={labelProps}
                      >
                        <SelectWithSearchInput
                          id="lesson"
                          defaultValue={""}
                          value={lesson}
                          disabled={Boolean(assessmentOnly) || !level}
                          handleOnChangeSelect={event => {
                            setLesson(event.target.value)
                          }}
                          style={{
                            borderRadius: "10px",
                            maxWidth: "100%",
                          }}
                          inputStyle={{
                            minHeight: "0px !important",
                          }}
                          menuListStyle={{
                            overflowX: "scroll",
                            maxHeight: "300px",
                          }}
                          name={FieldNames.Lesson}
                          optionsList={lessonsListByLevel}
                          placeholder="Select Lesson"
                          showSearchBar={false}
                          searchText={""}
                        />
                      </FormRow>
                    )}
                  </Field>
                )
              )}
            </Fragment>
          )}

          {loadingProgram ? (
            <Stack spacing={1} direction="row" className="mt-4" alignItems="center">
              <Skeleton variant="circular" width={21} height={21} />
              <ButtonSkeletonLoader width={254} height={21} />
            </Stack>
          ) : (
            feature_flags.curriculum_assessments_enabled &&
            isCurriculumProgram && (
              <Field type="checkbox" name={FieldNames.AssessmentOnly}>
                {() => (
                  <FormRow
                    label=""
                    className="mb-0"
                    ColProps={{ ...colProps, className: "d-flex mb-0" }}
                    LabelProps={labelProps}
                  >
                    <FormControlLabel
                      value={assessmentOnly}
                      checked={assessmentOnly}
                      control={
                        <Checkbox
                          onChange={e => {
                            const isChecked = e.target.checked
                            setAssessmentOnly(isChecked)
                            if (isChecked) {
                              setLesson("")
                              setLevel("")
                            } else {
                              setPreAssessment("")
                            }
                          }}
                        />
                      }
                      label="Assessment only (no lesson assigned)"
                      labelPlacement="end"
                    />
                  </FormRow>
                )}
              </Field>
            )
          )}

          {loadingProgram ? (
            <LookupFieldLoader />
          ) : (
            isCurriculumProgram &&
            feature_flags.curriculum_assessments_enabled &&
            assessmentOnly && (
              <Field type="text" name={FieldNames.Assessment}>
                {() => (
                  <FormRow
                    label="Assessment"
                    RowProps={{ className: "d-flex mb-0 flex-column" }}
                    ColProps={{ ...colProps, className: "d-flex mb-0 d-flex flex-column" }}
                    LabelProps={labelProps}
                  >
                    <SelectWithSearchInput
                      id="PreAssessment"
                      defaultValue={preAssessment}
                      value={preAssessment}
                      handleOnChangeSelect={event => {
                        setPreAssessment(event.target.value)
                      }}
                      style={{
                        borderRadius: "10px",
                        maxWidth: "100%",
                      }}
                      inputStyle={{
                        minHeight: "0px !important",
                      }}
                      menuListStyle={{
                        overflowX: "scroll",
                        maxHeight: "300px",
                      }}
                      name={FieldNames.Assessment}
                      optionsList={preAssessmentsList}
                      placeholder="Select Assessment"
                      showSearchBar={false}
                      searchText=""
                    />
                  </FormRow>
                )}
              </Field>
            )
          )}

          <hr className="my-4" />
          <Col>
            <Row className="gap-2 align-items-center">
              <PropsInjector props={{ submitDisabled: loading || Object.keys(errors).length > 0 }}>
                {children}
              </PropsInjector>
              {loading && <div className="spinner-border text-primary" />}
            </Row>
          </Col>
        </form>
      )}
    </FForm>
  )
}

export const CreateSessionDetailsForm = ({ onHide, session, maxBillableMin, hookConfig }) => {
  const { scheduleId, schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(hookConfig)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: "",
    [FieldNames.Duration]: "",
    [FieldNames.Location]: schedule.meeting_format || MeetingFormats.Online,
    [SyntheticFieldNames.Link]: schedule.meeting_info || "",
    [SyntheticFieldNames.Address]: schedule.address || "",
  })

  const onSubmit = formState => {
    setInitialValues(formState)
    const session = buildFormResult(formState)
    request({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
      },
      data: { session },
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(
    ({ submitDisabled }) => {
      return (
        <>
          <button className="btn btn-primary" type="submit" disabled={Boolean(submitDisabled)}>
            Create
          </button>
          <button className="btn btn-link btn-link-default" type="button" onClick={onHide}>
            Dismiss
          </button>
        </>
      )
    },
    [onHide]
  )
  return (
    <SessionDetailsForm
      date={session.started_at}
      loading={loading}
      pencilSpaceLink={schedule.pencil_space_link}
      initialValues={initialValues}
      maxBillableMin={maxBillableMin}
      onSubmit={onSubmit}
    >
      <Footer />
    </SessionDetailsForm>
  )
}

export const EditSessionDetailsForm = ({ onHide, session, maxBillableMin, hookConfig }) => {
  const { schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(hookConfig)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: new Date(formatDateTimeKeepZone(session.started_at)),
    [FieldNames.Duration]: session.duration,
    [FieldNames.Location]: session.meeting_format,
    [SyntheticFieldNames.Link]: session.meeting_info || schedule.meeting_info,
    [SyntheticFieldNames.Address]: schedule.address || "",
  })

  const onSubmit = formState => {
    setInitialValues(formState)
    const data = buildFormResult(formState, { ids: [session.id] })
    request({
      data,
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(
    ({ submitDisabled }) => {
      return (
        <>
          <button className="btn btn-primary" type="submit" disabled={Boolean(submitDisabled)}>
            Save
          </button>
          <button className="btn btn-link btn-link-default" type="button" onClick={onHide}>
            Cancel
          </button>
        </>
      )
    },
    [onHide]
  )
  return (
    <SessionDetailsForm
      date={session.started_at}
      session={session}
      loading={loading}
      initialValues={initialValues}
      maxBillableMin={maxBillableMin}
      onSubmit={onSubmit}
      pencilSpaceLink={schedule.pencil_space_link}
      touched
    >
      <Footer />
    </SessionDetailsForm>
  )
}

// todo - update request when pencil_spaces will be integrated
export const UnplannedSessionDetailsForm = ({ onHide, session, maxBillableMin }) => {
  const { groupSessionId, schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(createSessionFromReport)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: new Date(formatDateTimeKeepZone(session.started_at)),
    [FieldNames.Duration]: session.ps_duration,
    [FieldNames.LocationComment]: schedule.meeting_info || "",
  })
  const onSubmit = async data => {
    setInitialValues(data)
    await request({
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
      data: {
        group_session: {
          ...data,
          [FieldNames.Time]: formatDateTimeKeepZone(data[FieldNames.Time]),
          [FieldNames.Location]: MeetingFormats.LessonSpace,
          [FieldNames.LocationComment]: schedule.meeting_info || "",
        },
      },
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(({ submitDisabled }) => {
    return (
      <button className="btn btn-primary" type="submit" disabled={Boolean(submitDisabled)}>
        Confirm
      </button>
    )
  }, [])
  return (
    <>
      <Row className="mb-4">
        <span>Pencil Spaces tracked an unplanned session time.</span>
        <br />
        {/* <span>Please confirm or dismiss the tracked data.</span> */}
        <span>Please confirm the tracked data.</span>
      </Row>
      <SessionDetailsForm
        date={session.started_at}
        loading={loading}
        initialValues={initialValues}
        maxBillableMin={maxBillableMin}
        onSubmit={onSubmit}
        pencilSpaceLink={schedule.pencil_space_link}
        disableLocation
        touched
      >
        <Footer />
      </SessionDetailsForm>
    </>
  )
}
