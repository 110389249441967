import { putAndRedirect } from "@/api"
import arrayMutators from "final-form-arrays"
import React from "react"
import { Form } from "react-final-form"
import ProfileValidationProvider from "../providers/ProfileValidation"
import FormFields from "./FormFields"

const Degrees = ({ dataSources }) => {
  const onSubmit = profile => {
    putAndRedirect(dataSources.urls.save, dataSources.urls.next, { profile })
  }

  const validation = values => {
    const result = {}
    if (values?.degrees?.length === 0) result.degreesLength = "Required"
    return result
  }

  return (
    <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} validate={validation}>
      {({ handleSubmit, submitting, errors }) => (
        <form onSubmit={handleSubmit} className="container">
          <div className="row">
            <div className="col-lg-24">
              <ProfileValidationProvider errors={errors}>
                <FormFields dataSources={dataSources} />
              </ProfileValidationProvider>
            </div>
          </div>
          <button type="submit" className="btn btn-primary" disabled={submitting || Object.keys(errors).length > 0}>
            Next
          </button>
        </form>
      )}
    </Form>
  )
}
export default Degrees
