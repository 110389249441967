import moment from "moment"
import { minutesToHours } from "../../../../helpers/dates"
import { LearningLevel, MeetingFormats } from "../../../../constants"
import { OpeningStatuses, OpeningTutoringTypes, TutorPositionTypes } from "../../constants"

export const tutoringTypesMapper = type => {
  switch (type) {
    case OpeningTutoringTypes.Groups:
      return "Groups"
    case OpeningTutoringTypes.Individual:
      return "Individual sessions"
    default:
      return "—"
  }
}

export const tutorPositionMapper = type => {
  switch (type) {
    case TutorPositionTypes.Main:
      return "Main tutor"
    case TutorPositionTypes.Substitute:
      return "Substitute"
    default:
      return "—"
  }
}

export const dateRangeMapper = (start, end, mask = "MMMM D, YYYY") => {
  const range = [moment(start).format(mask)]
  if (end) range.push(moment(end).format(mask))
  return range.join(" — ")
}

export const rateMapper = (rate, type = "hour") => {
  if (typeof rate === "number" || typeof rate === "string") {
    const value = parseFloat(rate)
    return `$${value.toFixed(2)} / ${type}`
  } else return "—"
}

export const noShowsMapper = count => {
  if (typeof count === "number" && count > 0) return `${count} per tutor`
  return "—"
}

export const locationMapper = location => {
  switch (location) {
    case MeetingFormats.Offline:
      return "Offline"
    case MeetingFormats.Online:
      return "Online"
    case MeetingFormats.LessonSpace:
        return "Lessonspace"  
    default:
      return "—"
  }
}

export const durationMapper = duration => {
  const [hours, minutes] = minutesToHours(duration)
  const value = []
  if (hours > 0) value.push(`${hours} h`)
  if (minutes > 0) value.push(`${minutes} min`)
  return value.join(" ")
}

export const statusMapper = status => {
  switch (status) {
    case OpeningStatuses.Draft:
      return "Draft"
    case OpeningStatuses.Active:
      return "Active"
    case OpeningStatuses.Closed:
      return "Closed"
    default:
      return "—"
  }
}

export const startFromMapper = (start, format = "MMMM D, YYYY") => {
  const date = moment(start)
  // 3 days ago (September 1, 2022)
  // 1 day ago (September 1, 2022)
  // Today (September 1, 2022)
  // Tomorrow (September 1, 2022)
  // In 25 days (September 1, 2022)
  let prefix = ""
  const diff = date.diff(new Date(), "day")
  const diffAbs = Math.abs(diff)
  if (diff === 0) prefix = "Today"
  else if (diff === -1) prefix = "Tomorrow"
  else if (diff < 1) prefix = `${diffAbs} day${diffAbs > 1 ? "s" : ""} ago`
  else prefix = `In ${diffAbs} day${diffAbs > 1 ? "s" : ""}`

  return `${prefix} (${date.format(format)})`
}

export const learningLevelMapper = levelKey => {
  const { name } = LearningLevel.find(({ key }) => key === levelKey) || {}
  return name || "—"
}
