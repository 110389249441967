import BraintrustDataGrid from "@/common/DataGrid/index"
import StudentLoginCard from "@/common/StudentLoginCard"
import usePDFGeneration from "@/hooks/usePDFGeneration"
import { useGridApiRef } from "@mui/x-data-grid"
import React, { useCallback, useMemo, useState } from "react"
import StudentsDataGridToolbar from "./studentsDataGridToolbar"

const INITIAL_DENSITY = "standard"
const transformStudentData = students =>
  students.map((student, index) => ({
    id: index,
    identifier: student.id,
    name: `${student.first_name} ${student.last_name}`,
    email: student.email,
    password: student.password,
    groups: student.groups,
    grade: student.grade,
    status: student.status === "active" ? "Active" : "Inactive",
  }))

const StudentsTable = ({
  students = [],
  totalCount = 0,
  loading,
  pagination,
  googleSSOEnabled,
  handlePageChange,
  handleFilterModelChange,
  handleOpenPreviewDialog,
  filterOptions,
  filterModel,
  handleFilterChange,
  handleRemoveFilter,
  handleResetFilters,
  allRowsSelected,
  setAllRowsSelected,
  allStudents,
}) => {
  const apiRef = useGridApiRef()
  const [sortModel, setSortModel] = useState([{ field: "name", sort: "asc" }])
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [density, setDensity] = useState(INITIAL_DENSITY)

  const { isLoading, generatePDF } = usePDFGeneration(handleOpenPreviewDialog, {
    itemsPerPage: 8,
    pageSize: "letter",
    orientation: "portrait",
    renderItem: StudentLoginCard,
    renderItemProps: {
      googleSSOEnabled,
    },
  })

  const handleSelectAll = useCallback(() => {
    setAllRowsSelected(true)
    const allRowIds = apiRef.current?.getAllRowIds() || []
    setRowSelectionModel(allRowIds)
  }, [apiRef, setAllRowsSelected])

  const handleUnselectAll = useCallback(() => {
    setAllRowsSelected(false)
    setRowSelectionModel([])
  }, [setAllRowsSelected])

  const handleRowSelectionModelChange = useCallback(
    newRowSelectionModel => {
      setAllRowsSelected(false)
      setRowSelectionModel(newRowSelectionModel)
    },
    [setAllRowsSelected]
  )

  const handleDensityChange = useCallback(newDensity => {
    setDensity(newDensity)
  }, [])

  const handleGeneratePDF = useCallback(() => {
    if (allRowsSelected) {
      generatePDF(allRows)
    } else {
      const selectedRows = Array.from(apiRef.current.getSelectedRows().values())
      generatePDF(selectedRows)
    }
  }, [allRows, allRowsSelected, apiRef, generatePDF])

  const rows = useMemo(() => transformStudentData(students), [students])
  const allRows = useMemo(() => transformStudentData(allStudents), [allStudents])

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        headerClassName: "table-header",
        resizable: false,
        sortable: false,
        flex: 1,
        renderCell: params => (
          <a
            href={`/school_admin/students/${params.row.identifier}`}
            style={{ color: "#3c00f5", textDecoration: "none" }}
          >
            {params.row.name}
          </a>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        headerClassName: "table-header",
        resizable: false,
        sortable: false,
        flex: 1,
      },
      {
        field: "grade",
        headerName: "Grade",
        headerClassName: "table-header",
        resizable: false,
        sortable: false,
        flex: 1,
      },
      {
        field: "groups",
        headerName: "Groups",
        headerClassName: "table-header",
        resizable: false,
        sortable: false,
        flex: 1,
      },
    ],
    []
  )

  return (
    <BraintrustDataGrid
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      paginationModel={{ page: pagination?.page, pageSize: pagination?.pageSize }}
      onPaginationModelChange={handlePageChange}
      onFilterModelChange={handleFilterModelChange}
      pageSizeOptions={[20, 50, 100]}
      pagination
      filterMode="server"
      paginationMode="server"
      rowCount={totalCount}
      checkboxSelection
      keepNonExistentRowsSelected
      autoHeight
      loading={loading}
      density={density}
      onDensityChange={handleDensityChange}
      getRowId={row => row.id}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      rowSelectionModel={rowSelectionModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      slots={{ toolbar: StudentsDataGridToolbar }}
      disableColumnMenu
      slotProps={{
        toolbar: {
          isLoading,
          rows,
          rowSelectionModel,
          handleGeneratePDF,
          handleSelectAll,
          handleUnselectAll,
          filterOptions,
          filterModel,
          handleFilterChange,
          handleRemoveFilter,
          handleResetFilters,
          allRowsSelected,
          setAllRowsSelected,
          allRows: allRowsSelected ? allRows : rows,
        },
        noResultsOverlay: {
          message: "No students found",
        },
      }}
    />
  )
}

export default StudentsTable
