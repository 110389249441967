import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { useRef, useState } from "react"

export const useCreateGroupRequest = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const success = useRef(false)

  const request = async data => {
    try {
      setLoading(true)
      success.current = false
      await apiClient.post("/admin/opening_groups", data)
      success.current = true
      Alerter.success("Group successfully created")
      if (onSuccess) onSuccess()
    } catch ({ response: { data }, message }) {
      // eslint-disable-next-line no-console
      console.error(message)
      for (const key of Object.keys(data.errors)) {
        const value = data.errors[key]
        for (const error of value) {
          Alerter.error(`${key}: ${error}`)
        }
      }
    } finally {
      success.current ? setTimeout(() => setLoading(false), 1000) : setLoading(false)
    }
  }

  return { loading, request }
}

export const useUpdateGroupRequest = ({ id, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const success = useRef(false)

  const request = async data => {
    try {
      setLoading(true)
      success.current = false
      await apiClient.put(`/admin/opening_groups/${id}`, data)
      success.current = true
      Alerter.success("Group successfully updated")
      if (onSuccess) onSuccess()
    } catch ({ response: { data }, message }) {
      // eslint-disable-next-line no-console
      console.error(message)
      for (const key of Object.keys(data.errors)) {
        const value = data.errors[key]
        for (const error of value) {
          Alerter.error(`${key}: ${error}`)
        }
      }
    } finally {
      success.current ? setTimeout(() => setLoading(false), 1000) : setLoading(false)
    }
  }

  return { loading, request }
}
