import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"

import { apiClient } from "@/api"
import Alerter from "../common/alerter"
import AsyncAutocompleteField from "./AsyncAutocompleteField"
import FormFieldErrorFeedback from "./FormFieldErrorFeedback"

const OTHER_PROGRAM_NAME = "Custom"

const SelectTutorWithPrograms = ({
  tutorsSource,
  selTutor,
  selTutorErrors,
  selProgramId,
  selCustomProgramName,
  selProgramIdErrors,
  selCustomProgramErrors,
  modelName = "session",
  canEdit = true,
}) => {
  const [showOtherInput, setShowOtherInput] = useState(false)
  const [otherInputValue, setOtherInputValue] = useState(selCustomProgramName || "")
  const [programsOptions, setProgramsOptions] = useState([])

  const [programIdValue, setProgramIdValue] = useState(selProgramId || "")
  const onSelProgramIdChange = ({ target: { value } }) => {
    const selected = programsOptions.find(({ value: programValue }) => programValue === value)
    setShowOtherInput(selected?.title === OTHER_PROGRAM_NAME)
    setProgramIdValue(value)
  }

  const loadTutorPrograms = useCallback(
    tutor => {
      if (tutor) {
        apiClient
          .get(`/api/admin/tutors/${+tutor.value}/programs`, { params: { add_custom: true } })
          .then(({ data: { items } }) => {
            const selected = items.find(el => el.value === selProgramId)
            setShowOtherInput(selected?.title === OTHER_PROGRAM_NAME)
            setProgramsOptions(items)
          })
          .catch(() => Alerter.error("Autocomplete network/server error"))
      } else {
        setProgramsOptions([])
      }
    },
    [selProgramId]
  )

  const selectTutor = tutor => {
    loadTutorPrograms(tutor)
  }

  useEffect(() => loadTutorPrograms(selTutor), [loadTutorPrograms, selTutor])

  return (
    <div>
      <div
        className={"form-group row select required session_tutor_id" + (selTutorErrors ? " form-group-invalid" : "")}
      >
        <label className="col-form-label col-sm-7 col-md-5 col-lg-4 select required" htmlFor="session_tutor_id">
          Tutor
          <abbr title="required">*</abbr>
        </label>
        <div className="col-form-input col-sm-17 col-md-14 col-lg-10 col-xl-8">
          <AsyncAutocompleteField
            source={tutorsSource}
            fieldName={`${modelName}[tutor_id]`}
            selected={selTutor}
            validationErrors={selTutorErrors}
            onSelect={selectTutor}
            disabled={!canEdit}
          />
        </div>
      </div>

      <div
        className={
          "form-group row select required session_default_program_id" +
          (selProgramIdErrors ? " form-group-invalid" : "")
        }
      >
        <label
          className="col-form-label col-sm-7 col-md-5 col-lg-4 select required"
          htmlFor="session_default_program_id"
        >
          Default program
          <abbr title="required">*</abbr>
        </label>
        <div className="col-form-input col-sm-17 col-md-14 col-lg-10 col-xl-8">
          <select
            className={"form-control select required" + (selProgramIdErrors ? " is-invalid" : "")}
            name={`${modelName}[default_program_id]`}
            id="session_default_program_id"
            value={programIdValue}
            onChange={onSelProgramIdChange}
          >
            <option value="" data-title=""></option>
            {programsOptions.map(program => (
              <option key={program.value} value={program.value} data-title={program.title}>
                {program.title}
              </option>
            ))}
          </select>
          <FormFieldErrorFeedback message={selProgramIdErrors} />
          {showOtherInput && (
            <>
              <input
                className={"form-control mt-3 required" + (selCustomProgramErrors ? " is-invalid" : "")}
                required
                aria-required="true"
                type="text"
                value={otherInputValue}
                name={`${modelName}[custom_program]`}
                onChange={({ target: { value } }) => setOtherInputValue(value)}
                placeholder="Program name"
              />
              <FormFieldErrorFeedback message={selCustomProgramErrors} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

SelectTutorWithPrograms.propTypes = {
  tutorsSource: PropTypes.string.isRequired,
  selTutor: PropTypes.object,
  selTutorErrors: PropTypes.string,
  selProgramId: PropTypes.string,
  selProgramIdErrors: PropTypes.string,
}

export default SelectTutorWithPrograms
