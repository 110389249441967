import { post } from "@/api"
import GoogleLogo from "@/assets/images/logo-google.svg"
import Alerter from "common/alerter"
import React from "react"

const submitForm = e => {
  e.preventDefault()

  const formData = e.target

  const email = formData.querySelector('input[name="email"]').value
  const password = formData.querySelector('input[name="password"]').value

  post("/users/students/sign_in", { email: email, password: password })
    .then(function (response) {
      window.location.replace(response.data["redirect_url"])
    })
    .catch(function (error) {
      Alerter.error(error.response.data["error"])
    })
}

export const LoginForm = ({ googleOAuthUrl }) => (
  <main className="student-login-form-wrapper justify-content-center">
    <h1 className="student-login-form-title">Sign In</h1>
    <form className="student-login-form" onSubmit={submitForm}>
      <StudentLoginInput placeholder="Email" type="email" name="email" />
      <StudentLoginInput placeholder="Password" type="password" name="password" />
      <button className="student-login-form-button" type="submit">
        Sign In
      </button>
    </form>
    <div className="student-login-form-divider">
      <div className="student-login-form-divider-line" />
      <span className="student-login-form-divider-text">or</span>
      <div className="student-login-form-divider-line" />
    </div>
    <div className="student-login-form-social">
      <SocialLoginButton Logo={GoogleLogo} googleOAuthUrl={googleOAuthUrl}>
        Log in with Google
      </SocialLoginButton>
    </div>
    <p className="student-login-form-help-text">
      If you need help logging in, please contact your school administrator or&nbsp;
      <a href="mailto:tutor@braintrusttutors.com">contact Braintrust support</a>.
    </p>
  </main>
)

const StudentLoginInput = ({ placeholder, type, name }) => (
  <div className="student-login-form-input-field">
    <input className="student-login-form-input" name={name} placeholder={placeholder} type={type} />
  </div>
)

const SocialLoginButton = ({ Logo, googleOAuthUrl, children }) => (
  <a className="student-login-form-social-button" href={googleOAuthUrl}>
    <img src={Logo} className="mr-2 h-6" alt="Social Login Button" />
    {children}
  </a>
)
