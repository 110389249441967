import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import CloseIcon from "@mui/icons-material/Close"
import LockIcon from "@mui/icons-material/Lock"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { styled } from "@mui/system"
import React, { useState } from "react"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
}))

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))
const AuthenticationModal = ({ endpoint, setOpen }) => {
  const [password, setPassword] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async e => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      await apiClient.get(endpoint, { params: { password } })
      setOpen(false)
    } catch (error) {
      Alerter.error(error.response.data.error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <StyledDialog
      open={true}
      onClose={handleClose}
      aria-labelledby="authentication-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <StyledDialogTitle id="authentication-dialog-title">
        <Box display="flex" alignItems="center">
          <LockIcon fontSize="small" style={{ marginRight: 8 }} />
          <Typography variant="h6">Authentication</Typography>
        </Box>
        <IconButton aria-label="close" onClick={handleClose} size="small" sx={{ color: "primary.contrastText" }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <StyledTextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            inputProps={{ "aria-label": "Password" }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" variant="contained" disabled={!password || isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default AuthenticationModal
