import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { useCallback, useEffect, useState } from "react"

const INITIAL_FILTERS = {
  groups: [],
}

const useFilters = schoolId => {
  const [filterOptions, setFilterOptions] = useState(INITIAL_FILTERS)
  const [filterModel, setFilterModel] = useState({
    items: [],
    logicOperator: "and",
  })

  const fetchFilterOptions = useCallback(async () => {
    try {
      const url = `/api/v2/admins/students/filters${schoolId ? `?schools=${schoolId}` : ""}`
      const { data } = await apiClient.get(url)
      setFilterOptions(prevOptions => ({ ...prevOptions, ...data }))
    } catch (error) {
      Alerter.error("Error fetching filter options:", error)
    }
  }, [schoolId])

  useEffect(() => {
    fetchFilterOptions()
  }, [fetchFilterOptions])

  const handleFilterModelChange = useCallback(newFilterModel => {
    setFilterModel(newFilterModel)
  }, [])

  const handleFilterChange = useCallback((field, value) => {
    setFilterModel(prevModel => {
      const newItems = prevModel.items.filter(item => item.field !== field)
      if (value !== null && value !== undefined && (typeof value !== "object" || Object.keys(value).length > 0)) {
        newItems.push({
          field,
          operator: Array.isArray(value) ? "in" : "equals",
          value: value,
        })
      }
      return { ...prevModel, items: newItems }
    })
  }, [])

  const handleRemoveFilter = useCallback(
    field => {
      const newFilterModel = {
        ...filterModel,
        items: filterModel.items.filter(item => item.field !== field),
      }
      setFilterModel(newFilterModel)
    },
    [filterModel, setFilterModel]
  )

  const handleResetFilters = useCallback(() => {
    setFilterModel({
      items: [],
      logicOperator: "and",
    })
  }, [])

  return {
    filterOptions,
    filterModel,
    handleFilterModelChange,
    handleFilterChange,
    handleRemoveFilter,
    handleResetFilters,
  }
}

export default useFilters
