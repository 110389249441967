import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import { useCallback, useEffect, useState } from "react"

export const useGetPartnershipList = () => {
  const [complete, setComplete] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState()

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await apiClient.get("/api/admin/schools")
      setData(response.data)
    } catch (e) {
      Alerter.error(e.message)
    } finally {
      setComplete(true)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isLoading || complete) return
    getData()
  }, [complete, getData, isLoading])

  return [isLoading, data]
}
