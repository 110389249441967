export const replaceId = (url, id) => url.replace(":id", id)

export const handleRedirect = headers => {
  if (headers?.location) {
    window.location.replace(headers.location)
  }
}

export const joinErrors = errs => {
  if (typeof errs === "string") return errs
  return Object.values(errs).join("; ")
}

const isServer = typeof window === "undefined"

export const windowObj = isServer
  ? {
      location: { search: "", href: "" },
      addEventListener: () => {},
      removeEventListener: () => {},
    }
  : window

export const documentObj = isServer
  ? {
      content: "",
      querySelector: () => null,
    }
  : document

export default {
  window: windowObj,
  document: documentObj,
}
