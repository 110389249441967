import { postAndRedirect } from "@/api"
import arrayMutators from "final-form-arrays"
import React from "react"
import Button from "react-bootstrap/Button"
import { Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import {
  RejectionConfirmationDialog,
  useRejectionDialog,
} from "../rejection_confirmation_dialog/RejectionConfirmationDialog"
import Employment from "./Employment"

const onApprove = (values, urls) => {
  postAndRedirect(urls.approve, urls.redirect, {
    entity: { type: "TutorEmploymentHistory", ids: values.employment_ids },
  })
}

const buttonDisabled = form => {
  return !form.getFieldState("employment_ids") || form.getFieldState("employment_ids").length === 0
}

const EmploymentsToReview = ({ employmentsToShow, urls }) => {
  const { show, dialogProps } = useRejectionDialog()

  if (employmentsToShow.length === 0) {
    return null
  }

  return (
    <div className="col-lg-18 pending-area">
      <h5>Employment history</h5>

      <Form initialValues={{ employment_ids: [] }} onSubmit={() => {}} mutators={{ ...arrayMutators }}>
        {({ form }) => (
          <>
            <FieldArray name="employment_ids">
              {({ fields }) => (
                <>
                  {employmentsToShow.map(employment => (
                    <Employment key={employment.id} employment={employment} fields={fields} />
                  ))}
                </>
              )}
            </FieldArray>
            <hr className="thin" />
            <Button
              disabled={buttonDisabled(form)}
              variant="outline-primary"
              onClick={() => onApprove(form.getState().values, urls)}
              data-test="employment-approval"
            >
              Approve selected
            </Button>
            <Button
              disabled={buttonDisabled(form)}
              variant="secondary"
              className="reject"
              onClick={() => {
                show(form.getState().values.employment_ids, urls, "TutorEmploymentHistory", "record")
              }}
            >
              Reject selected
            </Button>
          </>
        )}
      </Form>

      <RejectionConfirmationDialog {...dialogProps} />
    </div>
  )
}

export default EmploymentsToReview
