import { find, map, reject } from "lodash/fp"
import React, { useEffect, useMemo, useState } from "react"

import { apiClient, replaceId, showAllErrorsInAlert } from "@/api"
import Alerter from "@/common/alerter"
import { REQUESTED_INTERVIEW, TIME_SUGGESTED_INTERVIEW } from "@/common/constants"
import { formatDateTimeKeepZone } from "@/helpers/dates"
import { getLabelByValue } from "@/helpers/presentational"
import { serializeTimeZones } from "@/helpers/serializers"
import IndeterminateProgressBar from "../../blocks/IndeterminateProgressBar"
import InterviewModal from "../schedule_interview/InterviewModal"
import InterviewPickTimeModal from "../schedule_interview/InterviewPickTimeModal"
import useFocusedInterview from "../useFocusedInterview"
import ParentRequestedInterviewCard from "./ParentRequestedInterviewCard"

const Interviews = ({ dataSources: { hierarchy, timeZones }, urls }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [interviews, setInterviews] = useState([])
  const [interviewIdToEdit, setInterviewIdToEdit] = useState(null)

  const focusedInterviewId = useFocusedInterview()

  const currentInterview = useMemo(() => {
    if (interviewIdToEdit) {
      return find(["id", interviewIdToEdit])(interviews)
    }
    return null
  }, [interviewIdToEdit, interviews])

  const tutorTimeZone = useMemo(() => {
    if (!currentInterview) {
      return null
    }
    const serializedTimeZones = serializeTimeZones(timeZones)
    return getLabelByValue(serializedTimeZones)(currentInterview.tutor.time_zone)
  }, [currentInterview, timeZones])

  useEffect(() => {
    getInterviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInterviews = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(urls.get, { params: { filter: REQUESTED_INTERVIEW } })
      setInterviews(data)
    } catch (e) {
      Alerter.error("An error occurred while loading data")
    } finally {
      setIsLoading(false)
    }
  }

  const onInterviewEdit = async values => {
    const serializedValues = {
      ...values,
      start_at_slots: values.start_at_slots.map(v => formatDateTimeKeepZone(v)),
    }
    try {
      const { data } = await apiClient.put(replaceId(urls.update, values.id), serializedValues)
      setInterviews(prevInterviews => map(item => (item.id === values.id ? data : item))(prevInterviews))
      setInterviewIdToEdit(null)
      Alerter.success("Interview was successfully updated")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const onInterviewConfirm = async values => {
    const serializedValues = {
      started_at: formatDateTimeKeepZone(values.started_at),
      call_details: values.call_details,
    }
    try {
      await apiClient.put(replaceId(urls.accept, currentInterview.id), serializedValues)
      setInterviews(prevInterviews => reject(["id", currentInterview.id])(prevInterviews))
      setInterviewIdToEdit(null)
      Alerter.success("Interview time has been successfully confirmed")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const onInterviewDelete = async id => {
    try {
      await apiClient.delete(replaceId(urls.decline, id))
      setInterviews(prevInterviews => reject(["id", id])(prevInterviews))
      Alerter.success("Interview was successfully deleted")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  return (
    <>
      {isLoading && <IndeterminateProgressBar />}
      <div className="p-4 p-md-5">
        {!isLoading && !interviews.length && <p className="text-center m-0">No interviews found</p>}
        {!isLoading &&
          interviews.length > 0 &&
          interviews.map(interview => (
            <ParentRequestedInterviewCard
              key={interview.id}
              interview={interview}
              hierarchy={hierarchy}
              focused={focusedInterviewId === interview.id}
              onEdit={() => setInterviewIdToEdit(interview.id)}
              onDelete={() => onInterviewDelete(interview.id)}
            />
          ))}
      </div>

      {currentInterview && currentInterview.status === REQUESTED_INTERVIEW && (
        <InterviewModal
          show={true}
          dataSources={{
            hierarchy,
            tutor: currentInterview.tutor,
            parent: currentInterview.parent,
            interview: currentInterview,
          }}
          urls={{ availability: replaceId(urls.availability, currentInterview.tutor.id) }}
          tutorTimeZone={tutorTimeZone}
          handleClose={() => setInterviewIdToEdit(null)}
          onSubmit={onInterviewEdit}
        />
      )}

      {currentInterview && currentInterview.status === TIME_SUGGESTED_INTERVIEW && (
        <InterviewPickTimeModal
          show={true}
          interview={currentInterview}
          onClose={() => setInterviewIdToEdit(null)}
          onSubmit={onInterviewConfirm}
        />
      )}
    </>
  )
}

export default Interviews
