import { apiClient } from "@/api"
import { showErrors } from "@/common/alerter"
import { useCallback, useEffect, useState } from "react"

export const useGetAdminMessageRequest = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState("")

  const request = useCallback(async () => {
    try {
      setLoading(true)
      const { data: result } = await apiClient.get(`/api/tutor/notation/tutor_dashboard`)
      setData(result.body || "")
    } catch (e) {
      showErrors(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    request()
  }, [request])

  return { loading, data }
}
