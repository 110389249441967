import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import CreateOrUpdateForm from "@/components/school_admins/school_admins/actions/create_or_update_form"
import { RECEIVE_SESSIONS_OPTIONS } from "@/constants"
import React from "react"

const EditSchoolAdmin = ({ schoolAdmin, schools, receiveSessions }) => {
  const onSubmit = async (event, id, email, firstName, lastName, receiveSessions, schoolId) => {
    event.preventDefault()

    const school_admin = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      school_id: schoolId,
      receive_session_reports: receiveSessions === RECEIVE_SESSIONS_OPTIONS.YES,
    }

    try {
      await apiClient.put(`/api/v2/school_admins/${id}`, school_admin)
      Alerter.success("BT School admin updated successfully")
      window.location.replace("/admin/school_admins")
    } catch (error) {
      Alerter.error(error.response.data.error)
    }
  }

  return (
    <CreateOrUpdateForm
      title={"Edit School Admin"}
      onSubmit={onSubmit}
      initialValues={{ schoolAdmin, receiveSessions }}
      schoolsList={schools}
    />
  )
}

export default EditSchoolAdmin
