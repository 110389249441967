import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { Field, Form } from "react-final-form"

import { apiClient, showBaseErrorsInAlert } from "@/api"
import { getSubmitErrors } from "@/helpers/forms"
import { serializeSpecialTreatments } from "@/helpers/serializers"
import React from "react"
import SpecialTreatmentPicker from "../../blocks/SpecialTreatmentPicker"
import UsersNavbar from "../../blocks/UsersNavbar"
import FormFields from "./FormFields"

const PersonalInfo = ({ dataSources: { user, urls, specialTreatments, grades, can_skip_student_info } }) => {
  const specialTreatmentOptions = serializeSpecialTreatments(specialTreatments)

  const onSubmit = async values => {
    try {
      await apiClient.put(urls.save, values)
      window.location.replace(urls.next)
    } catch (e) {
      showBaseErrorsInAlert(e)
      return getSubmitErrors(e)
    }
  }

  const onSkip = () => {
    window.location.replace(urls.next)
  }

  return (
    <Form initialValues={user.students[0]} onSubmit={onSubmit}>
      {({ handleSubmit, submitting }) => (
        <>
          <UsersNavbar title="Add a student" />
          <Container className="panel-wrapper">
            <Card className="panel mb-4" body>
              <h4>Student information</h4>
              <Row className="mb-2">
                <Col as="p" lg={15}>
                  Please fill in your personal information
                </Col>
              </Row>
              <FormFields specialTreatments={specialTreatments} grades={grades} />

              <Row className="mt-5" noGutters>
                <h4>Learning Differences?</h4>
              </Row>
              <Row className="mb-2">
                <Col as="p" lg={15}>
                  Please let us know if your child has any sort of a learning difference so we can be sure to suggest
                  the best teachers to support your family’s needs.
                </Col>
              </Row>
              <Field name="special_treatments">
                {({ input }) => <SpecialTreatmentPicker {...input} options={specialTreatmentOptions} />}
              </Field>
            </Card>
          </Container>
          <Container className="pb-5 bg-light-gray">
            <Row className="flex-nowrap mb-5 flex-column-reverse flex-md-row justify-content-center justify-content-md-end">
              {can_skip_student_info && (
                <Button variant="link" className="link-primary px-3 mr-3" onClick={onSkip}>
                  Skip for now
                </Button>
              )}
              <Button variant="outline-primary" className="mb-4 mb-md-0" disabled={submitting} onClick={handleSubmit}>
                Save and continue
              </Button>
            </Row>
          </Container>
        </>
      )}
    </Form>
  )
}

export default PersonalInfo
