import { postAndRedirect } from "@/api"
import { BREAK_REASONS } from "@/constants"
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Reasons from "./Reasons"

const BreakModal = ({ url, nextUrl, payload }) => {
  const [show, setShow] = useState(false)
  const [reasonIndex, setReasonIndex] = useState(null)
  const [otherReason, setOtherReason] = useState("")

  const onRemove = () => {
    const data = payload || {}
    data.reason = reasonIndex === -1 ? otherReason : BREAK_REASONS[reasonIndex]
    postAndRedirect(url, nextUrl, data)
  }

  return (
    <>
      <Button
        variant="outline-danger"
        block
        onClick={() => {
          setShow(true)
        }}
      >
        Break connection
      </Button>

      <Modal
        animation={false}
        size="lg"
        scrollable={true}
        show={show}
        onHide={() => {
          setShow(false)
        }}
        dialogClassName="programs-modal"
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title>Please select reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Reasons
            reasonIndex={reasonIndex}
            setReasonIndex={setReasonIndex}
            otherReason={otherReason}
            setOtherReason={setOtherReason}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-primary" disabled={reasonIndex === null} onClick={onRemove}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BreakModal
