import React, { useContext, Fragment, useState, useCallback, useMemo, useRef } from "react"
import { Col, Row } from "react-bootstrap"
import moment from "moment"
import { Link, useNavigate } from "react-router-dom"
import {
  DataContext,
  ValidationContext,
  QueryContext,
  DomainTransactionContext,
  DataContextActions,
} from "../../../contexts"
import { formatDateKeepZone } from "../../../helpers/dates"
import { CreateSessionDetailsForm, EditSessionDetailsForm, CancelSessionForm } from "../forms"
import useViewport from "../../../hooks/useViewport"
import {
  ValidationNames,
  MeetingFormatTitles,
  DayActions,
  SessionActions,
  ScheduleActions,
  SessionStatuses,
} from "../constants"
import { Entities } from "../../../constants"
import { className } from "../../../helpers/className"
import { ReactComponent as ReportOverdue } from "../../../assets/images/report-overdue.svg"
import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg"

import { WeekRange } from "./sessions_range"
import { PropsInjector } from "../../PropsInjector"
import WeekDays from "./week_days"
import ScheduleContextProvider from "../providers/schedule_context_provider"
import {
  finishSession,
  bulkDeleteSessionInReport,
  getWeekScheduleDays,
  getWeekScheduleSessions,
  getReport,
  bulkUpdateSessionInReport,
  createSessionFromReport,
  pendReport,
  cancelSession,
} from "../configurations/domain"
import { checkActionExist } from "../helpers"
import Dialog from "../../blocks/Dialog"
import { DayTile, SessionTile } from "./tiles"

const FormSwitch = ({ action, session, onHide }) => {
  const { schedule } = useContext(DataContext)

  switch (action) {
    case DayActions.CreateSession:
      return (
        <CreateSessionDetailsForm
          session={session}
          maxBillableMin={schedule.max_duration || 0}
          onHide={onHide}
          hookConfig={createSessionFromReport}
        />
      )
    case SessionActions.Update:
      return (
        <EditSessionDetailsForm
          session={session}
          maxBillableMin={schedule.max_duration || 0}
          hookConfig={bulkUpdateSessionInReport}
          onHide={onHide}
        />
      )
    default:
      return null
  }
}

// const modalTitleMap = {
//   [DayActions.CreateSession]: "Unplanned session",
//   [SessionActions.Update]: "Edit session",
// }

const FormEditModal = ({ show, onHide, action, session }) => {
  const startedAt = session?.started_at ? moment.parseZone(session.started_at) : null
  const formattedDate = startedAt ? startedAt.format("dddd, MMMM D, YYYY") : "No date available"
  return (
    <Dialog title={`Session Details: ${formattedDate}`} open={show} onClose={onHide} className="mw-60">
      <FormSwitch action={action} session={session} onHide={onHide} />
    </Dialog>
  )
}

const CancelModalContent = ({ session, onHide }) => {
  const { schedule } = useContext(DataContext)
  const { id, educatable, meeting_format, connections } = schedule
  const connection = connections.find(connection => connection.id === session.connection_id)
  return (
    <>
      <div className="school-session_cancel-modal-title py-4">
        You’re canceling Group session
        <br />
        <span className="font-weight-semibold">
          {educatable.name} on {meeting_format === "online" ? "Web" : MeetingFormatTitles[meeting_format]},{" "}
          {formatDateKeepZone(session.started_at, "MMMM D, h:mma")}
        </span>
      </div>
      <CancelSessionForm
        scheduleId={id}
        session={session}
        haveConnection={Boolean(connection)}
        maxCancelCount={connection?.max_paid_noshows ?? 0}
        currentCancelCount={connection?.fact_paid_noshows ?? 0}
        onHide={onHide}
      />
    </>
  )
}

const FormCancelModal = ({ session, show, onHide }) => {
  return (
    <Dialog size="lg" open={show} onClose={onHide}>
      <CancelModalContent session={session} onHide={onHide} />
    </Dialog>
  )
}

const EditableDaysActions = ({ children }) => {
  const { role, scheduleId } = useContext(DataContext)
  const { getRequest } = useContext(QueryContext)
  const [showModal, setShowModal] = useState(null)
  const [editableDay, setEditableDay] = useState({})

  const onHideModal = useCallback(() => {
    setShowModal(null)
    setTimeout(() => setEditableDay({}), 250)
  }, [])

  const onChange = useCallback(({ action, session }) => {
    setEditableDay({ action, session })
    setShowModal("edit")
  }, [])

  const onCancel = useCallback(
    async (_, { action, session, params }) => {
      if (session.status === SessionStatuses.Pending) {
        setEditableDay({ action, session })
        setShowModal("cancel")
      } else {
        const data = { status: params.status }

        if (data.status === SessionStatuses.Cancelled) {
          data.cancellation_reason = `Changed session status from "${session.status}" to "${params.status}" by ${role}`
        }

        const request = getRequest(cancelSession)
        await request({
          entitiesIds: {
            [Entities.Schedules]: scheduleId,
            [Entities.Sessions]: session.id,
          },
          data,
        })
      }
    },
    [getRequest, role, scheduleId]
  )

  const onPend = useCallback(
    async (_, { session }) => {
      const apiRequest = getRequest(pendReport)
      await apiRequest({
        entitiesIds: {
          [Entities.Schedules]: scheduleId,
          [Entities.Sessions]: session.id,
        },
      })
    },
    [getRequest, scheduleId]
  )

  const onFinish = useCallback(
    async (_, { session }) => {
      const apiRequest = getRequest(finishSession)
      await apiRequest({
        entitiesIds: {
          [Entities.Schedules]: scheduleId,
          [Entities.Sessions]: session.id,
        },
      })
    },
    [getRequest, scheduleId]
  )

  const onDestroy = useCallback(
    async (_, { session }) => {
      const apiRequest = getRequest(bulkDeleteSessionInReport)
      await apiRequest({
        data: { ids: [session.id] },
      })
    },
    [getRequest]
  )

  const props = {
    onChange,
    onCancel,
    onPend,
    onFinish,
    onDestroy,
  }

  return (
    <>
      <PropsInjector props={props}>{children}</PropsInjector>
      <FormEditModal
        show={showModal === "edit"}
        session={editableDay.session}
        action={editableDay.action}
        onHide={onHideModal}
      />
      <FormCancelModal show={showModal === "cancel"} session={editableDay.session} onHide={onHideModal} />
    </>
  )
}

const EditableDaysMapper = ({ days, sessions, onChange, onCancel, onPend, onFinish, onDestroy }) => {
  const canUpdate = useCallback(session => checkActionExist(session.actions, SessionActions.Update), [])

  return days.map(({ date, actions, session_ids }) => {
    const [sessionId] = session_ids
    const session = sessions[sessionId] || null
    if (!session) {
      return (
        <DayTile
          key={date}
          date={date}
          actions={actions}
          actionsHandlers={{
            [DayActions.CreateSession]: () =>
              onChange({
                action: DayActions.CreateSession,
                session: { started_at: date },
              }),
          }}
        />
      )
    }
    return (
      <SessionTile
        key={date}
        session={session}
        onClick={canUpdate(session) ? () => onChange({ action: SessionActions.Update, session }) : void 0}
        actionHandlers={{
          [SessionActions.Pend]: onPend,
          [SessionActions.Finish]: onFinish,
          [SessionActions.Cancel]: onCancel,
          [SessionActions.Destroy]: onDestroy,
        }}
      />
    )
  })
}

const CalendarSelector = ({ readOnly, days, sessions, markedDays }) => {
  return (
    <ScheduleContextProvider
      config={{
        short: false,
        showDayIcon: false,
        month: false,
        highlighted: new Set([]),
        marked: readOnly ? void 0 : markedDays,
      }}
    >
      <EditableDaysActions>
        <EditableDaysMapper days={days} sessions={sessions} />
      </EditableDaysActions>
    </ScheduleContextProvider>
  )
}

export const NeedAction = ({ children }) => {
  return (
    <div className="card shadow-none fullwidth px-3 py-2 action-needed-tip flex-row align-items-center">
      <div className="action-needed-tip_icon flex-shrink-0 mr-1">
        <ReportOverdue />
      </div>
      <div className="action-needed-tip_text">{children}</div>
    </div>
  )
}

const NeedActionAttendance = ({ dates }) => {
  const datesRow = useMemo(() => dates.map(date => moment(date).format("dddd")).join(", "), [dates])
  return (
    <NeedAction>
      No students who attended the session{dates.length > 1 ? "s" : ""} on{" "}
      <span className="font-weight-semibold">{datesRow}</span>
    </NeedAction>
  )
}

const WeekNavigation = () => {
  const timerRef = useRef(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const { getQueryState } = useContext(QueryContext)
  const navigate = useNavigate()
  const { report_date, scheduleId, schedule } = useContext(DataContext)
  const { updateState, resetFields } = useContext(DataContextActions)
  const { isMobileViewport } = useViewport()
  const { request: weekDaysRequest } = getQueryState(getWeekScheduleDays)
  const { request: weekSessionsRequest } = getQueryState(getWeekScheduleSessions)
  const { request: reportRequest } = getQueryState(getReport)
  const { buildTransaction } = useContext(DomainTransactionContext)

  const getDataByDate = useCallback(
    date => {
      const from = date.format("YYYY-MM-DD")
      setShowSpinner(true)
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(async () => {
        const weekRequestParams = {
          from,
          to: moment(from).endOf("week").format("YYYY-MM-DD"),
        }
        const transaction = buildTransaction()
        const requests = [
          reportRequest({
            entitiesIds: {
              [Entities.Schedules]: scheduleId,
              [Entities.Reports]: from,
            },
            transaction,
          }),
          weekDaysRequest({
            entitiesIds: {
              [Entities.Schedules]: scheduleId,
            },
            params: weekRequestParams,
            transaction,
          }),
          weekSessionsRequest({
            entitiesIds: {
              [Entities.Schedules]: scheduleId,
            },
            params: weekRequestParams,
            transaction,
          }),
        ]

        updateState("report_date", from)
        resetFields(["availablePrograms"])
        await Promise.all(requests)
        navigate(`../${from}`)
        setShowSpinner(false)
      }, 300)
    },
    [
      buildTransaction,
      navigate,
      reportRequest,
      resetFields,
      scheduleId,
      updateState,
      weekDaysRequest,
      weekSessionsRequest,
    ]
  )

  return (
    <>
      {showSpinner && !isMobileViewport && <div className="spinner-border text-primary mr-2" />}
      <button
        disabled={moment(report_date).endOf("week").subtract(1, "week").isBefore(schedule.start_on)}
        onClick={() => getDataByDate(moment(report_date).startOf("week").subtract(1, "week"))}
        className="btn btn-outline-primary icon-32-px p-0 mr-2 btn-circle"
      >
        <div className="icon-16-px icon-width-8-px icon-height-10-px rotate-180-deg">
          <Arrow />
        </div>
      </button>
      {showSpinner && isMobileViewport && <div className="spinner-border text-primary mr-2" />}
      <button
        disabled={schedule.end_on ? moment(report_date).startOf("week").add(1, "week").isAfter(schedule.end_on) : false}
        onClick={() => getDataByDate(moment(report_date).startOf("week").add(1, "week"))}
        className="btn btn-outline-primary icon-32-px p-0 btn-circle"
      >
        <div className="icon-16-px icon-width-8-px icon-height-10-px">
          <Arrow />
        </div>
      </button>
    </>
  )
}

const WeekSchedule = ({ className: weekClass }) => {
  const { report, report_date, schedule, scheduleDays, scheduleSessions } = useContext(DataContext)
  const { getValidationValueByName } = useContext(ValidationContext)
  const sessions = useMemo(
    () =>
      scheduleSessions.reduce((acc, session) => {
        acc[session.id] = session
        return acc
      }, {}),
    [scheduleSessions]
  )

  const markedSet = useMemo(() => {
    const marked = new Set()
    scheduleSessions.forEach(session => {
      if (session.report_overdue) marked.add(formatDateKeepZone(session.started_at))
    })
    return marked
  }, [scheduleSessions])

  const { status } = report

  const readonly = useMemo(
    () => !checkActionExist(schedule.available_actions, ScheduleActions.ManageSessions),
    [schedule]
  )

  const weekWarnings = getValidationValueByName(ValidationNames.AttendanceWarnings)

  const wrapperClassName = className(
    "card",
    "p-4",
    weekClass,
    (status === "action_needed" || weekWarnings.length) && "-action-needed"
  )

  return (
    <div className={wrapperClassName}>
      <Col>
        <Row className="mb-4 row align-items-center justify-content-between gap-1">
          <Col as="h3" className="session-calendar-title d-flex" xs={24} lg="auto">
            <Link to={`/${schedule.id}?month=${report_date}`} className="btn btn-light icon-32-px p-0 btn-circle mr-2">
              <div className="icon-16-px icon-width-8-px icon-height-10-px rotate-180-deg">
                <Arrow />
              </div>
            </Link>
            <span className="mr-1">Week of</span>
            <WeekRange />
          </Col>
          <Col xs={24} lg="auto" className="d-flex align-items-center justify-content-between flex-nowrap">
            <WeekNavigation />
          </Col>
        </Row>
        <Row>
          <div className="d-flex flex-column flex-grow-1 flex-nowrap overflow-x-auto mx-n4 px-4">
            <WeekDays week={scheduleDays} />
            <div className="d-flex flex-grow-1 flex-nowrap justify-content-between mt-2">
              <CalendarSelector days={scheduleDays} sessions={sessions} markedDays={markedSet} readOnly={readonly} />
            </div>
          </div>
        </Row>
        {weekWarnings.length > 0 && (
          <Row className="mt-3">
            <NeedActionAttendance dates={weekWarnings} />
          </Row>
        )}
      </Col>
    </div>
  )
}

export default WeekSchedule
