import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FilterListIcon from "@mui/icons-material/FilterList"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Tooltip, Typography } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { styled } from "@mui/material/styles"
import React, { Fragment, memo, useState } from "react"
import FilterInputs from "./Filters"

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  fontFamily: theme.typography.fontFamily,
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f8f9fa",
  "&:before": {
    display: "none",
  },
}))

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  minHeight: 56,
  "&.Mui-expanded": {
    minHeight: 56,
  },
  "& .MuiAccordionSummary-content": {
    margin: `${theme.spacing(1)} 0`,
  },
}))

const ToolbarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1, 2),
  borderBottom: "1px solid #e0e0e0",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
  },
}))

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(1),
  },
}))

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[60],
  color: theme.palette.neutral[120],
  padding: theme.spacing(0.5),
  fontWeight: 700,
}))

const StudentsDataGridToolbar = ({
  handleSelectAll,
  handleUnselectAll,
  rowSelectionModel,
  isSelectAllDisabled,
  allRowsSelected,
  status,
  ...props
}) => {
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false)

  const activeFilters = props.filterModel?.items.filter(item => item.value).length

  const getSelectedText = () => {
    if (rowSelectionModel.length === 0) {
      return "No students selected"
    } else if (rowSelectionModel.length === 1) {
      return "1 student selected"
    } else {
      return `${rowSelectionModel.length} students selected`
    }
  }

  const getSelectAllTooltip = () => {
    if (allRowsSelected) {
      return "All active students are already selected"
    } else if (isSelectAllDisabled) {
      return "This is only available for small student lists. Please use filters to narrow down your list."
    }
    return "Select all active students"
  }

  return (
    <Fragment>
      <ToolbarContainer>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" component="h2" color="text.primary" mr={2}>
            Student Selection
          </Typography>
          <StyledChip label={getSelectedText()} size="small" />
        </Box>
        <ButtonGroup>
          <Tooltip title={getSelectAllTooltip()}>
            <span>
              <Button
                size="small"
                onClick={handleSelectAll}
                disabled={isSelectAllDisabled}
                startIcon={status.isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                aria-label="Select all active students"
              >
                Select All Active
              </Button>
            </span>
          </Tooltip>
          <Button
            size="small"
            onClick={handleUnselectAll}
            disabled={!rowSelectionModel.length}
            aria-label="Deselect all students"
          >
            Deselect All
          </Button>
        </ButtonGroup>
      </ToolbarContainer>

      <AccordionStyled expanded={isFiltersExpanded} onChange={() => setIsFiltersExpanded(!isFiltersExpanded)}>
        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
          <Box display="flex" alignItems="center">
            <FilterListIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle1" fontWeight="600" color="text.primary">
              Filters
            </Typography>
            {activeFilters > 0 && (
              <Tooltip title={`${activeFilters} active filters`}>
                <StyledChip label={activeFilters} size="small" sx={{ ml: 1 }} />
              </Tooltip>
            )}
          </Box>
        </AccordionSummaryStyled>
        <AccordionDetails>
          <FilterInputs {...props} />
        </AccordionDetails>
      </AccordionStyled>
    </Fragment>
  )
}

export default memo(StudentsDataGridToolbar)
