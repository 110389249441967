import React, { useMemo, useState } from "react"
import WizardCard from "./WizardCard"

import { apiClient } from "@/api"
import FormRow from "@/components/blocks/FormRow"
import { validateEmail } from "@/helpers/validators"
import { Form } from "react-bootstrap"

const WizardEmailCard = ({ userEmail, onSubmit }) => {
  const [email, setEmail] = useState(userEmail)

  const emailValidationError = useMemo(() => (email ? validateEmail(email) : ""), [email])
  const hasValidEmail = !!email && !emailValidationError

  const sendNewcomerEmail = () => {
    apiClient.post("/api/parent/public/newcomer", { email })
  }

  const onButtonClick = () => {
    if (hasValidEmail) {
      window?.localStorage.setItem("registration_email", email)
      sendNewcomerEmail()
    }
    onSubmit()
  }

  const onKeyDown = e => {
    if (e.key === "Enter" && !emailValidationError) {
      onButtonClick()
    }
  }

  return (
    <WizardCard
      title="What is your email address?"
      buttonText={hasValidEmail ? "Continue" : "Skip for now"}
      onButtonClick={onButtonClick}
    >
      <div className="mb-4">
        We will create a profile for you to save your search results. We promise we won&apos;t attack your inbox!
      </div>
      <FormRow ColProps={{ lg: 24 }}>
        <Form.Control
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="john.doe@yahoo.com"
          autoFocus
          isInvalid={!!emailValidationError}
          onKeyDown={onKeyDown}
        />
        <Form.Control.Feedback type="invalid">{emailValidationError}</Form.Control.Feedback>
      </FormRow>
    </WizardCard>
  )
}

export default WizardEmailCard
