import { find, reject } from "lodash/fp"
import React, { useEffect, useMemo, useState } from "react"

import { apiClient, replaceId, showAllErrorsInAlert } from "@/api"
import Alerter from "@/common/alerter"
import { DECLINED_REQUEST, PENDING_REQUEST } from "@/common/constants"
import { getLabelByValue } from "@/helpers/presentational"
import IndeterminateProgressBar from "../../blocks/IndeterminateProgressBar"
import SingleChoiceDropdown from "../../blocks/SingleChoiceDropdown"
import RequestModal from "../../schedule_session/RequestModal"
import SessionCallDetailsModal from "./SessionCallDetailsModal"
import SessionDeclineModal from "./SessionDeclineModal"
import SessionRequestCard from "./SessionRequestCard"

const statusOptions = [
  { value: PENDING_REQUEST, label: "Active requests" },
  { value: DECLINED_REQUEST, label: "Declined requests" },
]

const getStatusFilterText = status => `Show: ${getLabelByValue(statusOptions)(status).toLowerCase()}`

const SessionRequests = ({ dataSources, urls, rate }) => {
  const [status, setStatus] = useState(PENDING_REQUEST)
  const [requests, setRequests] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showRequestId, setShowRequestId] = useState(null)
  const [requestIdToConfirm, setRequestIdToConfirm] = useState(null)
  const [requestIdToDecline, setRequestIdToDecline] = useState(null)
  const [confirmingRequests, setConfirmingRequests] = useState({})
  const [decliningRequests, setDecliningRequests] = useState({})

  const currentRequest = useMemo(() => {
    if (showRequestId) {
      return find(["id", showRequestId])(requests)
    }
    return null
  }, [showRequestId, requests])

  useEffect(() => {
    getReviews(status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getReviews = async status => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(urls.get, { params: { filter: status } })
      setRequests(data)
    } catch (e) {
      Alerter.error("An error occurred while loading data")
    } finally {
      setIsLoading(false)
    }
  }

  const confirmRequest = async (id, values) => {
    setConfirmingRequests(prevState => ({ ...prevState, [id]: true }))
    try {
      const confirmUrl = replaceId(urls.confirm, id)
      await apiClient.post(confirmUrl, values)
      Alerter.success("Request was confirmed")

      const filteredRequests = reject(["id", id])(requests)
      setRequests(filteredRequests)
    } catch (e) {
      showAllErrorsInAlert(e)
    } finally {
      setConfirmingRequests(prevState => ({ ...prevState, [id]: false }))
    }
  }

  const declineRequest = async (id, values) => {
    setDecliningRequests(prevState => ({ ...prevState, [id]: true }))
    try {
      const declineUrl = replaceId(urls.decline, id)
      await apiClient.put(declineUrl, values)
      Alerter.success("Request was declined")

      const filteredRequests = reject(["id", id])(requests)
      setRequests(filteredRequests)
    } catch (e) {
      showAllErrorsInAlert(e)
    } finally {
      setDecliningRequests(prevState => ({ ...prevState, [id]: false }))
    }
  }

  const onChangeStatus = value => {
    getReviews(value)
    setStatus(value)
  }

  const onSubmitSessionDetails = values => {
    confirmRequest(requestIdToConfirm, values)
    setRequestIdToConfirm(null)
  }

  const onSessionDecline = values => {
    declineRequest(requestIdToDecline, values)
    setRequestIdToDecline(null)
  }

  return (
    <>
      {isLoading && <IndeterminateProgressBar />}
      <div className="p-4 p-md-5">
        <SingleChoiceDropdown
          value={status}
          className="mb-4"
          buttonText={getStatusFilterText(status)}
          options={statusOptions}
          onChange={onChangeStatus}
        />
        {!isLoading && !requests.length && <p className="text-center m-0">No requests found</p>}
        {!isLoading &&
          requests.length > 0 &&
          requests.map(request => (
            <SessionRequestCard
              key={request.id}
              sessionRequest={request}
              dataSources={dataSources}
              rate={rate}
              saveMessageUrl={urls.save_message}
              enableMessageButtonUrl={urls.enable_message_button}
              isConfirmationPending={!!confirmingRequests[request.id]}
              isDeclinePending={!!decliningRequests[request.id]}
              onRequestConfirm={() => (request.online ? setRequestIdToConfirm(request.id) : confirmRequest(request.id))}
              onRequestDecline={() => setRequestIdToDecline(request.id)}
              onRequestShow={() => setShowRequestId(request.id)}
            />
          ))}
      </div>

      {currentRequest && (
        <RequestModal
          show={true}
          editMode={false}
          handleClose={() => setShowRequestId(false)}
          dataSources={{
            ...dataSources,
            parent: { ...currentRequest.parent, students: [currentRequest.student] },
            request: currentRequest,
          }}
          urls={{ availability: replaceId(urls.availability, dataSources.tutor.id) }}
          timeZone={dataSources.tutor.time_zone}
          hourRate={currentRequest.hourly_rate}
        />
      )}
      {requestIdToConfirm && (
        <SessionCallDetailsModal
          show={true}
          onSubmit={onSubmitSessionDetails}
          onClose={() => setRequestIdToConfirm(null)}
        />
      )}
      {requestIdToDecline && (
        <SessionDeclineModal show={true} onSubmit={onSessionDecline} onClose={() => setRequestIdToDecline(null)} />
      )}
    </>
  )
}

export default SessionRequests
