import React, { useEffect, useState } from "react"

import { apiClient, replaceId, showAllErrorsInAlert } from "@/api"
import Alerter from "@/common/alerter"
import { last, map, reject } from "lodash/fp"
import { Button } from "react-bootstrap"
import IndeterminateProgressBar from "../blocks/IndeterminateProgressBar"
import ReviewDeleteConfirmationModal from "./ReviewDeleteConfirmationModal"
import ReviewListItem from "./ReviewListItem"
import ReviewModal from "./ReviewModal"

const VISIBLE_REVIEW_COUNT = 3

const ReviewList = ({ user, allowFullName, urls, isAdmin, tutorReviews }) => {
  const [currentReview, setCurrentReview] = useState(null)
  const [reviewIdToDelete, setReviewIdToDelete] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [reviews, setReviews] = useState(tutorReviews && tutorReviews.length > 0 ? tutorReviews : [])
  const [totalReviewCount, setTotalReviewCount] = useState(
    tutorReviews && tutorReviews.length > 0 ? tutorReviews.length : 0
  )
  const [lastReviewId, setLastReviewId] = useState(
    tutorReviews && tutorReviews.length > 0 ? last(tutorReviews)?.id : null
  )

  useEffect(() => {
    if (reviews && reviews.length > 0) {
      setTotalReviewCount(reviews)
      setLastReviewId(last(reviews)?.id)
      setReviews(reviews)
    } else {
      getReviews()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getReviews = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(urls.get, {
        params: { last_id: lastReviewId, size: VISIBLE_REVIEW_COUNT },
      })
      const newReviews = [...reviews, ...data.reviews]
      setTotalReviewCount(data.total_count)
      setLastReviewId(last(newReviews)?.id)
      setReviews(newReviews)
    } catch (e) {
      showAllErrorsInAlert(e)
    } finally {
      setIsLoading(false)
    }
  }

  const editReview = async values => {
    try {
      const { data } = await apiClient.put(replaceId(urls.update, currentReview.id), values)
      setReviews(prevReviews => map(item => (item.id === values.id ? data : item))(prevReviews))

      Alerter.success("Your review was successfully changed")
    } catch (e) {
      showAllErrorsInAlert(e)
    } finally {
      setCurrentReview(null)
    }
  }

  const deleteReview = async id => {
    try {
      await apiClient.delete(replaceId(urls.delete, id))
      setReviews(prevReviews => reject(["id", id])(prevReviews))
      Alerter.success("Your review was successfully deleted")
    } catch (e) {
      showAllErrorsInAlert(e)
    } finally {
      setCurrentReview(null)
      setReviewIdToDelete(null)
    }
  }

  const showMore = async () => {
    setIsLoadingMore(true)
    await getReviews()
    setIsLoadingMore(false)
  }

  return (
    <>
      {isLoading && <IndeterminateProgressBar className="mb-4" />}
      {reviews.map(review => (
        <ReviewListItem
          key={review.id}
          review={review}
          user={user}
          allowFullName={allowFullName}
          className="mb-5"
          isAdmin={isAdmin}
          onEdit={() => setCurrentReview(review)}
          onDelete={() => setReviewIdToDelete(review.id)}
        />
      ))}
      {currentReview && (
        <ReviewModal
          show={true}
          review={currentReview}
          tutor={currentReview.tutor}
          onSubmit={editReview}
          onDelete={() => deleteReview(currentReview.id)}
          handleClose={() => setCurrentReview(null)}
        />
      )}
      {reviewIdToDelete && (
        <ReviewDeleteConfirmationModal
          show={true}
          handleClose={() => setReviewIdToDelete(null)}
          onConfirm={() => deleteReview(reviewIdToDelete)}
        />
      )}
      {totalReviewCount > 0 && totalReviewCount > reviews.length && (
        <Button variant="outline-primary" size="sm" className="px-4" disabled={isLoadingMore} onClick={showMore}>
          <span className={isLoadingMore ? "invisible" : "visible"}>Show more reviews</span>
          {isLoadingMore && (
            <div className="spinner-wrapper">
              <div className="spinner-border text-white" />
            </div>
          )}
        </Button>
      )}
    </>
  )
}

export default ReviewList
