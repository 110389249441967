/**
 * Creates and configures an API client with interceptors.
 *
 * This module initializes an API client using the createApiClient function
 * and sets up interceptors for the client using the setupInterceptors function.
 * The configured API client is then exported as the default export.
 *
 * @module apiClientSetup
 * @requires ./apiClient
 * @requires ./interceptors
 *
 * @exports {Object} The configured API client
 */
import { createApiClient } from "./apiClient"
import { setupInterceptors } from "./interceptors"

const apiClient = createApiClient()
setupInterceptors(apiClient)

export default apiClient
